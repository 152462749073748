import { Pipe, PipeTransform } from '@angular/core';

export const transformRegion = (region: number): any => {
    if (region === undefined) {
        return region;
    }

    switch (region) {
        case 0:
            return 'Todas las regiones';
        case 1:
            return 'Región de Tarapacá';
        case 2:
             return 'Región de Antofagasta';
        case 3:
            return 'Región de Atacama';
        case 4:
             return 'Región de Coquimbo';
        case 5:
            return 'Región de Valparaíso';
        case 6:
             return 'Región del Libertador General Bernardo O’Higgins';
        case 7:
            return 'Región del Maule';
        case 8:
            return 'Región del Biobío';
        case 9:
             return 'Región de La Araucanía';
        case 10:
            return 'Región de Los Lagos';
        case 11:
            return 'Región Aysén del General Carlos Ibáñez del Campo';
        case 12:
             return 'Región de Magallanes y Antártica Chilena';
        case 13:
            return 'Región Metropolitana de Santiago';
        case 14:
            return 'Región de Los Ríos';
        case 15:
            return 'Región de Arica y Parinacota';
        case 16:
            return 'Región de Ñuble';
        default:
            return region;
    }
}

@Pipe({
    name: 'biddingRegion',
    pure: false
})
export class BiddingRegionPipe implements PipeTransform {

    transform(region: number): any {
        return transformRegion(region);
    }

    reverseTransform(region: string){
        if (region === undefined) {
            return region;
        }

        switch (region) {
            case 'Región de Tarapacá':
                return 1;
            case 'Región de Antofagasta':
                return 2;
            case 'Región de Atacama':
                return 3;
            case 'Región de Coquimbo':
                return 4;
            case 'Región de Valparaíso':
                return 5;
            case 'Región del Libertador General Bernardo O’Higgins':
                return 6;
            case 'Región del Maule':
                return 7;
            case 'Región del Biobío':
                return 8;
            case 'Región de La Araucanía':
                return 9;
            case 'Región de Los Lagos':
                return 10;
            case 'Región Aysén del General Carlos Ibáñez del Campo':
                return 11;
            case 'Región de Magallanes y Antártica Chilena':
                return 12;
            case 'Región Metropolitana de Santiago':
                return 13;
            case 'Región de Los Ríos':
                return 14;
            case 'Región de Arica y Parinacota':
                return 15;
            case 'Región de Ñuble':
                return 16;
            default:
                return region;
        }
    }
    
}