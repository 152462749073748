import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';
import { NotificationConfig } from '../models/notificationConfig.model';

export interface MsgObject{
    id: string,
    msg: string,
    users: Array<string>,
    date: any
  }

@Injectable(
    {
        providedIn: 'root'
    }
)
export class NotificationService {

    constructor(
        public _httpClient: HttpClient,
        public _apiService: ApiService,
    ) {}

    getNotifications() {
        return this._httpClient.get(environment.apiUrl + '/web-notifications', {
            headers: this._apiService.getHeaders(),
        });
    }

    sendNotification(msgObject: MsgObject) {
        return this._httpClient.post(environment.apiUrl + '/web-notifications/send', msgObject, {
            headers: this._apiService.getHeaders(),
        });
    }

    deleteNotification(msgObject: MsgObject){
        return this._httpClient.delete(environment.apiUrl + '/web-notifications/'+msgObject.id,{
            headers: this._apiService.getHeaders()
        });
    }

    getNotificationConfig(filter) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/user-notification-configs', {
            headers: this._apiService.getHeaders(),
            params: params
        });
    }

    patchNotificationConfig(notificationConfig: NotificationConfig) {
        return this._httpClient.patch(environment.apiUrl + '/user-notification-configs', notificationConfig, {
            headers: this._apiService.getHeaders(),
        });
    }

    deleteNotificationConfig(){
        return this._httpClient.delete(environment.apiUrl + '/user-notification-configs', {
            headers:  this._apiService.getHeaders(),
        });
    }

}