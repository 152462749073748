import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class MarketDashboardService {

    constructor(
        public _httpClient: HttpClient,
        public _apiService: ApiService,
    ) {}


    getAllBiddings(filter) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/user-biddings', {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingWithMaxAmount(filter: any) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/bidding-with-max-amount', {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingCount(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/bidding-count/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingPerDay(filter: any) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/biddings-per-day', {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingPerDate(filter: any, type: number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/biddings-per-date/' + type, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getProviders(filter) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/providers', {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getTop10(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/top-10-biddings/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingAmountPerRegion(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/bidding-amount-per-region/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingAmountPerCategory(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/market-dashboard/bidding-amount-per-category/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

}