import { Pipe, PipeTransform } from '@angular/core';

export const transformMillion = (value: any): any => {

    if(+value < 1000000 && +value >= 1000) return Math.round(+value/1000)
    else if(+value >= 1000000) return Math.round(+value/1000000)
    else return +value
  }

@Pipe({
  name: 'million'
})
export class MillionPipe implements PipeTransform {

  transform(value: any): any {
    return transformMillion(value);
  }
  
}