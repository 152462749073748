import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "./api.service";

@Injectable()
export class BiddingService implements Resolve<any> {
    currentUser: any = {};
    biddings: any[];
    onBiddingsChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        public _apiService: ApiService
    ) {
        // Set the defaults
        this.onBiddingsChanged = new BehaviorSubject({});
        this.currentUser = JSON.parse(sessionStorage.getItem("user"));
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getBiddings()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get biddings
     *
     * @returns {Promise<any>}
     */
    getBiddings(): Promise<any> {
        const filter: any = JSON.parse(
            sessionStorage.getItem("bidding-filter")
        );
        const params: HttpParams = new HttpParams().set(
            "filter",
            JSON.stringify(filter)
        );

        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + "/user-biddings", {
                    headers: this._apiService.getHeaders(),
                    params: params,
                })
                .subscribe((response: any) => {
                    this.biddings = response;
                    this.onBiddingsChanged.next(this.biddings);
                    resolve(response);
                }, reject);
        });
    }

    getAllBiddings(filter) {
        const params: HttpParams = new HttpParams().set(
            "filter",
            JSON.stringify(filter)
        );
        return this._httpClient.get(environment.apiUrl + "/user-biddings", {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getAllGeneralBiddings(filter) {
        const params: HttpParams = new HttpParams().set(
            "filter",
            JSON.stringify(filter)
        );
        return this._httpClient.get(
            environment.apiUrl + "/general-user-biddings",
            {
                headers: this._apiService.getHeaders(),
                params: params,
            }
        );
    }

    downloadBiddings(filter){
        const params: HttpParams = new HttpParams().set(
            "filter",
            JSON.stringify(filter)
        );
        return this._httpClient.get(
            environment.apiUrl + "/user-biddings/download",
            {
                headers: this._apiService.getHeaders(),
                params: params,
            }
        );
    }
}
