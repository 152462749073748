import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

export interface loginData {
    username: string;
    password: string;
}

@Injectable({
    providedIn: "root",
})
export class LoginService {
    constructor(public httpClient: HttpClient, private router: Router) {}

    userLogin(loginData: loginData): any {
        return this.httpClient.post(
            environment.apiUrl + "/users/login",
            loginData
        );
    }

    logout(): void {
        sessionStorage.clear();
        window.location.reload();
        // this.router.navigateByUrl("/");
    }
}
