import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';


@Injectable(
    {
        providedIn: 'root'
    }
)
export class UserService {

    constructor(
        public _httpClient: HttpClient,
        public _apiService: ApiService,
    ) {}

    getUsers(filter: any) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/users', {
            headers: this._apiService.getHeaders(),
            params: params
        });
    }

}