import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginService, loginData } from "app/shared/services/login.service";
import { environment } from '../../../environments/environment';

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    profiles: any[] = [];
    adminUrl: string = environment.adminUrl;
    loginForm: FormGroup;
    latestProfile: any;
    latestProfileKeys: any;
    dashboard;
    busyLogin;
    timestamp: string;
    inMaintenance: boolean;
    showPassword: boolean = false;

    companyTheme: string;

    constructor(
        private router: Router,
        private _fuseConfigService: FuseConfigService,
        private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _loginService: LoginService
    ) {
        /**
         * Layout of fuse view
         */
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this.busyLogin = false;
    }

    ngOnInit(): void {
        this.redirectUserSession();
        /*
        console.log("(window.location.hostname) 👉", window.location.hostname);
        if (window.location.hostname.includes("ferrovial.smartlicitaciones.cl")) {
            this.inMaintenance = true;
        }*/
        this.loginForm = this._formBuilder.group({
            username: ["", [Validators.required, Validators.minLength(3)]],
            password: ["", [Validators.required, Validators.minLength(3)]],
        });
        
    }

    onSubmit(loginData: loginData): void {
        this.busyLogin = true;

        this._loginService.userLogin(loginData).subscribe(
            (response: any) => {
                this.busyLogin = false;

                if (!this.isUserAuthenticated()) {
                    sessionStorage.setItem("token", response.token);

                    sessionStorage.setItem(
                        "user",
                        JSON.stringify(response.user)
                    );

                    localStorage.setItem(
                        "colorPalette",
                        JSON.stringify(response.colorPalette)
                    );
                    this.redirectUserSession();
                } else {
                    this.router.navigateByUrl("/");
                    this._snackBar.open(
                        "Ya se encuentra un usuario con el mismo nombre ingresado",
                        "Cerrar",
                        {
                            duration: 5000,
                        }
                    );
                }
            },
            (error: any) => {
                switch (error.error.error.statusCode) {
                    case 401:
                        this._snackBar.open(
                            "Usuario y/o contraseña inválidas",
                            "Cerrar",
                            {
                                duration: 5000,
                            }
                        );
                        this.busyLogin = false;
                        break;
                    default:
                        this._snackBar.open(
                            "Error al iniciar sesion",
                            "Cerrar",
                            {
                                duration: 5000,
                            }
                        );
                        this.busyLogin = false;
                        break;
                }
            }
        );
    }

    isUserAuthenticated(): boolean {
        if (sessionStorage.getItem("user") !== null) return true;
        else {
            return false;
        }
    }

    redirectUserSession(){
        if(this.isUserAuthenticated()){
            const user = JSON.parse(sessionStorage.getItem("user"));

            if (user.organizationCode === "ferrovial") {
                window.location.href = "app/wallet-dashboard";
                // this.router
                //     .navigate(["app/wallet-dashboard"])
                //     .then(() => {
                //         window.location.reload();
                //     });
            } else {
                window.location.href = "app/general-dashboard";
                // this.router
                //     .navigate(["app/general-dashboard"])
                //     .then(() => {
                //         window.location.reload();
                //     });
            }
        }
    }
}
