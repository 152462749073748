import { generalNavigation, navigation } from "app/navigation/navigation";

export const getAppNavigation = () => {
    const currentUser = sessionStorage.getItem("user");
    const parsedUser = JSON.parse(currentUser);
    const isFerrovial =
        parsedUser && parsedUser.organizationCode === "ferrovial";
    return isFerrovial ? navigation : generalNavigation;
};

export const getUserData = () => {
    const currentUser = sessionStorage.getItem("user");
    return currentUser ? JSON.parse(currentUser): null;
}