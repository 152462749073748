import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'biddingColumns',
    pure: false
})
export class BiddingColumnsPipe implements PipeTransform {
    transform(col: string): any {
        if (col === undefined) {
            return col;
        }

        switch (col) {
            case 'code':
                return 'Código'
            case 'name':
                return 'Nombre'
            case 'categoryName':
                return 'Categoría'
            case 'detailUrl':
                return 'Link de detalle'
            case 'headline':
                return 'Titular'
            case 'estimatedAmount':
                return 'Valor estimado (Peso)'
            case 'estimatedAmountDolar':
                return 'Valor estimado (Dolar)'
            case 'estimatedAmountUf':
                return 'Valor estimado (UF)'
            case 'publicationDate':
                return 'Fecha de publicación'
            case 'status':
                return 'Estado'
            case 'origin':
                return 'Origen'
            case 'technicalOpeningDate':
                return 'Fecha acto apertura técnica'
            case 'economicOpeningDate':
                return 'Fecha acto apertura económica'
            case 'awardDate':
                return 'Fecha adjudicación'
            case 'visitLandDate':
                return 'Fecha visita terreno'
            case 'financingSource':
                return 'Fuente de financiamiento'
            case 'timeUnit':
                return 'Unidad de tiempo'
            case 'modality':
                return 'Modalidad'
            case 'contractResponsibleName':
                return 'Nombre responsable del contrato'
            case 'contractResponsibleEmail':
                return 'Email responsable del contrato'
            case 'contractDuration':
                return 'Duración del contrato'
            case 'workArea1':
                return 'Rubro1'
            case 'workArea2':
                return 'Rubro2'
            case 'oferentsNumber':
                return 'Número de oferentes'
            case 'providerRut':
                return 'Rut del proveedor'
            case 'providerName':
                return 'Nombre del proveedor'
            case 'estimatedAmountAwarded':
                return 'Monto estimado adjudicado'
            case 'currencyOffer':
                return 'Moneda de la oferta'
            case 'offeredTotalValue':
                return 'Valor total ofertado'
            case 'description':
                return 'Descripción'    
            case 'documents':
                return 'Documentos' 
            case 'customStatus':
                return 'Mis estados'
            case 'typology':
                return 'Sigla Tipología'
            case 'typologyString':
                return 'Tipología'
            case 'closingDate':
                return 'Fecha de cierre' 
            case 'region':
                return 'Región' 
            
            /*
            case 'visitAddress':
                return 'Dirección visita'
            case 'offeredAmount':
                return 'Cantidad ofrecida'
            case 'time':
                return 'Tiempo'*/
            default:
                return col;
        }
    }
}