<div class="notification-dialogs">
    <h1 mat-dialog-title class="new-notification-header">
        Nueva notificación
    </h1>
    <mat-dialog-content >
        <form name="form" fxLayout="row wrap" fxLayoutAlign="center" [formGroup]="configNotificationForm">
            
            <p *ngIf="!data.notificationConfig">Usted no ha configurado sus notificaciones. Complete el formulario para que empiecen a registrarse avisos diariamente en esta tabla y vía correo.</p>
            <p *ngIf="data.notificationConfig">A continuación, se encuentra la configuración almacenada para la generación automática de notifcaciones diarias.</p>
            <div class="p-8 p-sm-8" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                <div fxLayout="column" fxFlex="1 1 auto">
                    <mat-form-field class="w100-percent" appearance="outline" fxLayout="row" fxLayoutAlign="start center">
                        <mat-label>Monto minimo (CLP)</mat-label>
                        <input matInput formControlName="amount" (keyup)="formatNumber()">
                    </mat-form-field>
                </div>
            </div>

            <div class="p-8 p-sm-8" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                <div fxLayout="column" fxFlex="1 1 auto">
                    <mat-form-field class="w100-percent" appearance="outline" fxLayout="row" fxLayoutAlign="start center">
                        <mat-label>Regiones</mat-label>
                        <mat-select formControlName="region">
                            <mat-option *ngFor="let r of data.regions" [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
        <div class="p-8 p-sm-8 ml-auto">
            <button mat-raised-button class="mr-8" 
            [mat-dialog-close]="false">
                Cancelar
            </button>
        </div>
        <div class="p-8 p-sm-8">
            <button mat-raised-button 
            *ngIf="data.notificationConfig"
            [mat-dialog-close]="{
                deleteConfig: true
            }"
            >
                Limpiar configuracion
            </button>
        </div>
        <div class="p-8 p-sm-8">
            <button 
                mat-raised-button
                class="send-notification-btn" 
                [mat-dialog-close]="configNotificationForm.getRawValue()"
                [disabled]="configNotificationForm.invalid">
                Guardar
            </button>
        </div>
    </mat-dialog-actions>
</div>