export const DISPLAYED_TABLE_COLUMNS = [
    'code',
    'name',
    'categoryName',
    'estimatedAmount', 
    'estimatedAmountDolar', 
    'publicationDate', 
    'awardDate',
    'status', 
    'origin'
]

export const ALL_TABLE_COLUMNS = [
    'code',
    'name',
    'categoryName',
    'detailUrl',
    'headline',
    'estimatedAmount',
    'estimatedAmountDolar',
    'estimatedAmountUf',
    'estimatedAmountAwarded',
    //'offeredAmount',
    'currencyOffer',
    'contractDuration',
    'publicationDate',
    'awardDate',
    'technicalOpeningDate',
    'economicOpeningDate',
    'closingDate',
    'visitLandDate',
    //'visitAddress',
    //'time',
    'modality',
    'typology',
    'typologyString',
    'region',
    'financingSource',
    'status',
    'origin',
    'workArea1',
    'workArea2',
    'oferentsNumber',
    'providerRut',
    'providerName',
    'offeredTotalValue',
    'customStatus',
    'documents',
    'description'
]

export const DISPLAYED_NOTIFICATION_TABLE_COLUMNS = [
    'total',
    'date',
    'message',
]

export const ALL_NOTIFICATION_TABLE_COLUMNS = [
    'total',
    'date',
    'message',
]