export type ColorPalette = {
    name: string;
    primary: string;
    secondary: string;
    main: string;
    accent: string;
    textAccent: string;
    charts: string[];
};

export const defaultColorPalette = {
    name: "Default",
    primary: "#3c9ba7",
    secondary: "#f55236",
    main: "#2d323e",
    accent: "#3c9ba7",
    textAccent: "#ffffff",
    charts: [
        "#EBB700",
        "#FFAA5A",
        "#FFD25A",
        "#BBBCBB",
        "#747678",
        "#ffc700",
        "#FFF05A",
        "#FF785A",
    ],
};

export const loadColorPalette = (): ColorPalette => {
    let colorPalette = JSON.parse(localStorage.getItem("colorPalette"));

    if (!colorPalette) {
        colorPalette = defaultColorPalette;
    }
    document.body.style.setProperty("--primary-color", colorPalette.primary);
    document.body.style.setProperty("--secondary-color",colorPalette.secondary);
    document.body.style.setProperty("--main-color", colorPalette.main);
    document.body.style.setProperty("--accent-color", colorPalette.accent);
    document.body.style.setProperty(
        "--text-accent-color",
        colorPalette.textAccent
    );

    return colorPalette;
};
