import { NgModule } from "@angular/core";

import { GeneralDashboardComponent } from "./general-dashboard.component";

import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { CommonModule } from "@angular/common";
import { MatSortModule } from "@angular/material/sort";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseWidgetModule } from "@fuse/components/widget/widget.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FuseSidebarModule } from "@fuse/components/sidebar/sidebar.module";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { PipesModule } from "app/shared/pipes/pipes.module";
import { OriginVariablesPipe } from "app/shared/pipes/origin-variables.pipe";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FormatNumberPipe } from "app/shared/pipes/format-number.pipe";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ReportBtnModule } from 'app/layout/components/report-btn/report-btn.module';
import { MillionPipe } from "app/shared/pipes/million.pipe";

export const MY_FORMATS = {
    parse: {
        dateInput: "DD-MM-YYYY",
    },
    display: {
        dateInput: "DD-MM-YYYY",
        monthYearLabel: "YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "YYYY",
    },
};

@NgModule({
    declarations: [GeneralDashboardComponent],
    providers: [
        OriginVariablesPipe,
        MillionPipe,
        FormatNumberPipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    entryComponents: [],
    imports: [
        //Material
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSortModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressBarModule,
        MatDatepickerModule,

        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule,
        FuseSidebarModule,

        NgxChartsModule,
        NgxDaterangepickerMd.forRoot(),

        PipesModule,
        ReportBtnModule
    ],
    exports: [GeneralDashboardComponent],
})
export class GeneralDashboardModule {}
