import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class BiddingDashboardService {

    constructor(
        public _httpClient: HttpClient,
        public _apiService: ApiService,
    ) {}

    getBiddingWithMaxAmount(filter: any) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/bidding-dashboard/bidding-with-max-amount', {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingCount(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/bidding-dashboard/bidding-count/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getTop10(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/bidding-dashboard/top-10-biddings/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingAmountPerRegion(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/bidding-dashboard/bidding-amount-per-region/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

    getBiddingAmountPerCategory(filter: any, currency:  number) {
        const params: HttpParams = new HttpParams().set('filter', JSON.stringify(filter));
        return this._httpClient.get(environment.apiUrl + '/bidding-dashboard/bidding-amount-per-category/' + currency, {
            headers: this._apiService.getHeaders(),
            params: params,
        });
    }

}