import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';

const loginRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent 
    }
];

export const LOGIN_ROUTES = RouterModule.forChild( loginRoutes );