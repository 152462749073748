<div id="biddings" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div
            class="header accent"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center"
        >
            <!-- APP TITLE -->
            <div
                class="logo mb-8 mb-md-0"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-icon
                    class="logo-icon s-32 mr-16"
                    [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                >
                    assignment
                </mat-icon>
                <span
                    class="logo-text h1 accent-text"
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', x: '-25px' }
                    }"
                >
                    Licitaciones
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper fuse-white mx-32 mx-md-0">
                <div
                    class="search"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Buscar licitación" />
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- EXPORT BUTTON -->
            <button
                mat-raised-button
                (click)="exportData()"
                class="export-data-btn mt-8 mt-md-0"
            >
                <span>Descargar datos</span>
            </button>
            <!-- / EXPORT BUTTON -->

            <!-- ADD COLUMNS -->
            <mat-form-field appearance="fill" class="col-select mt-8 mt-md-0">
                <mat-label>Parámetros de tabla</mat-label>
                <mat-select
                    [(value)]="displayedColumns"
                    multiple
                    (selectionChange)="selectColumns($event.value)"
                >
                    <mat-option *ngFor="let col of allColumns" [value]="col">{{
                        col | biddingColumns
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- / ADD COLUMNS -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card mb-16">
            <mat-progress-bar
                *ngIf="
                    dataSource == undefined || dataSource == null || isLoading
                "
                [color]="'warn'"
                mode="indeterminate"
            >
            </mat-progress-bar>

            <mat-table
                class="biddings-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Código</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.code }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Nombre</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate name-field">
                            {{ bidding.name }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="categoryName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Categoría</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate category-field">
                            {{ bidding.categoryName }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="detailUrl">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Link de detalle</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <a
                            style="color: #7a99ac"
                            target="_blank"
                            href="{{ bidding.detailUrl }}"
                            class="text-truncate"
                            >{{ bidding.detailUrl }}</a
                        >
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="headline">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Titular</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.headline }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estimatedAmount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Valor estimado (CLP)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.estimatedAmount == "NaN"
                                    ? ""
                                    : (bidding.estimatedAmount
                                      | million
                                      | formatNumber: bidding.estimatedAmount)
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estimatedAmountDolar">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Valor estimado (US Dólar)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.estimatedAmountDolar == "NaN"
                                    ? ""
                                    : (bidding.estimatedAmountDolar
                                      | million
                                      | formatNumber
                                          : bidding.estimatedAmountDolar)
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estimatedAmountUf">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Valor estimado (UF)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.estimatedAmountUf == "NaN"
                                    ? ""
                                    : (bidding.estimatedAmountUf
                                      | million
                                      | formatNumber: bidding.estimatedAmountUf)
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="publicationDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha de publicación</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.publicationDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Estado</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.status }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="origin">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Origen</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.origin | biddingOrigin }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="technicalOpeningDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha acto apertura técnica</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.technicalOpeningDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="economicOpeningDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha acto apertura económica</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.economicOpeningDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="awardDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha adjudicación</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.awardDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="visitLandDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha visita terreno</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.visitLandDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="closingDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha de cierre</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{
                                bidding.closingDate
                                    | date: "dd-MM-yyyy HH:mm":"utc"
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!--
                <ng-container matColumnDef="visitAddress">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Dirección visita</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.visitAddress }}</p>
                    </mat-cell>
                </ng-container>
                -->

                <ng-container matColumnDef="financingSource">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fuente de financiamiento</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.financingSource }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!--
                <ng-container matColumnDef="time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Tiempo</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.time }}</p>
                    </mat-cell>
                </ng-container>
                -->

                <ng-container matColumnDef="modality">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Modalidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.modality }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="contractDuration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Duración del contrato (Días)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.contractDuration }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="oferentsNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Número de oferentes</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.oferentsNumber }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="providerRut">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Rut del proveedor</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.providerRut }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="providerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Adjudicatorio</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.providerName }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estimatedAmountAwarded">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Monto estimado adjudicado</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.estimatedAmountAwarded }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!--
                <ng-container matColumnDef="offeredAmount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Cantidad ofrecida</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.offeredAmount }}</p>
                    </mat-cell>
                </ng-container>
                -->

                <ng-container matColumnDef="currencyOffer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Moneda de la oferta</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.currencyOffer }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="offeredTotalValue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Valor total ofertado</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.offeredTotalValue }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Descripción</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.description }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="documents">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Documentos</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <ul>
                            <li *ngFor="let doc of bidding.documents">
                                <p class="text-truncate">
                                    {{ doc.name }} - {{ doc.href }}
                                </p>
                            </li>
                        </ul>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Mis estados</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.customStatus }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="workArea1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Rubro1</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.workArea1 }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="workArea2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Rubro2</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.workArea2 }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="typology">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Sigla Tipología</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">{{ bidding.typology }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="typologyString">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Tipología</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.typologyString }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Región</mat-header-cell
                    >
                    <mat-cell *matCellDef="let bidding">
                        <p class="text-truncate">
                            {{ bidding.region | biddingRegion }}
                        </p>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let bidding; columns: displayedColumns"
                    matRipple
                >
                </mat-row>
            </mat-table>

            <mat-paginator
                #paginator
                [length]="dataSourceLength"
                [pageIndex]="0"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100,1000]"
                (page)="onPageChange($event)"
            >
            </mat-paginator>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<button
    mat-icon-button
    class="
        filter-icon
        mat-elevation-z2
        market-dashboard-config-button
        right-side-panel
    "
    (click)="toggleSidebarOpen('marketDashboardConfig')"
>
    <img class="logo" src="assets/icons/filter.svg" />
</button>

<fuse-sidebar
    name="marketDashboardConfig"
    class="market-dashboard-config-sidebar"
    position="right"
    [invisibleOverlay]="true"
>
    <div class="market-dashboard-config-panel" fusePerfectScrollbar>
        <div class="header">
            <span class="title">Filtro dashboard mercado</span>

            <button
                mat-icon-button
                class="close-button"
                (click)="toggleSidebarOpen('marketDashboardConfig')"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!-- 
            FORM 
        -->
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="group">
                <!-- 
                    Publication Date Field 
                -->
                <span class="mb-4"><strong>Fecha de publicación:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Fecha inicial</mat-label>
                        <input
                            formControlName="startPublicationDate"
                            matInput
                            [matDatepicker]="startPublicationDatePicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="startPublicationDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                            #startPublicationDatePicker
                        ></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Fecha final</mat-label>
                        <input
                            formControlName="endPublicationDate"
                            matInput
                            [matDatepicker]="endPublicationDatePicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="endPublicationDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                            #endPublicationDatePicker
                        ></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- 
                    / Publication Date Field 
                -->

                <!-- 
                    Award Date Field 
                -->
                <span class="mb-4"
                    ><strong>Fecha de adjudicación:</strong></span
                >

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Fecha inicial</mat-label>
                        <input
                            formControlName="startDate"
                            matInput
                            [matDatepicker]="startDatePicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="startDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Fecha final</mat-label>
                        <input
                            formControlName="endDate"
                            matInput
                            [matDatepicker]="endDatePicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="endDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- 
                    / Award Date Field 
                -->

                <span class="mb-4"><strong>Valores:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Moneda</mat-label>
                        <mat-select formControlName="currency">
                            <mat-option [value]="0"> CLP </mat-option>
                            <mat-option [value]="1"> US Dólar </mat-option>
                            <mat-option [value]="2"> UF </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- 
                    Estimated Amount Field 
                -->
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Monto inical</mat-label>
                        <input
                            matInput
                            formControlName="startAmount"
                            (keyup)="formatNumber('startAmount')"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Monto final</mat-label>
                        <input
                            matInput
                            formControlName="endAmount"
                            (keyup)="formatNumber('endAmount')"
                        />
                    </mat-form-field>
                </div>
                <!-- 
                    / Estimated Amount Field 
                -->

                <!-- 
                    Region Field 
                -->
                <span class="mb-4"><strong>Ubicación:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Región</mat-label>
                        <mat-select formControlName="region">
                            <mat-option
                                *ngFor="let region of regions"
                                [value]="region.value"
                            >
                                {{ region.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- 
                    / Region Field 
                -->

                <span class="mb-4"><strong>Adjudicatarios:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Adjudicatario</mat-label>
                        <input
                            type="text"
                            placeholder="Elegir uno"
                            aria-label="Adjudicatario"
                            matInput
                            [formControl]="form.get('providerName')"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let p of filteredProviders | async"
                                [value]="p"
                            >
                                {{ p }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- 
                    /  Field 
                -->

                <div fxLayout="row" fxLayoutAlign="center center">
                    <button
                        class="mt-16 w-60-p"
                        mat-raised-button
                        type="submit"
                        color="accent"
                    >
                        Filtrar
                    </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center">
                    <button
                        style="background-color: red"
                        class="mt-16 w-60-p"
                        mat-raised-button
                        (click)="clearFilter()"
                    >
                        Borrar filtro
                    </button>
                </div>
            </div>
        </form>
        <!-- 
            / END FORM 
        -->
    </div>
</fuse-sidebar>
