<div class="notification-dialogs">
    <h1 mat-dialog-title class="new-notification-header">
        Nueva notificación
    </h1>
    <mat-dialog-content >
        <form name="form" fxLayout="row wrap" fxLayoutAlign="center" [formGroup]="newNotificationForm">

            <div class="p-8 p-sm-8" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                <div fxLayout="column" fxFlex="1 1 auto">
                    <mat-form-field class="w100-percent" appearance="outline" fxLayout="row" fxLayoutAlign="start center">
                        <mat-label>Usuarios</mat-label>
                        <mat-select formControlName="users" multiple="true" searchable="true">
                            <mat-option *ngFor="let user of data.users" [value]="user.id">
                                {{user.firstname}} {{user.lastname}} 
                            </mat-option>
                        </mat-select>
                        <mat-error>Se debe seleccionar al menos un usuario</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="p-8 p-sm-8" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                <div fxLayout="column" fxFlex="1 1 auto">
                    <mat-form-field class="w100-percent" appearance="outline" fxLayout="row" fxLayoutAlign="start center">
                        <mat-label>Mensaje</mat-label>
                        <textarea  matInput formControlName="msg" required></textarea>
                        <mat-error>El mensaje es requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
        <div class="p-8 p-sm-8 ml-auto">
            <button mat-raised-button class="mr-8" mat-dialog-close="false">
                Cancelar
            </button>
        </div>
        <div class="p-8 p-sm-8">
            <button 
                mat-raised-button
                class="send-notification-btn" 
                [mat-dialog-close]="newNotificationForm.getRawValue()"
                [disabled]="newNotificationForm.invalid">
                Enviar notificación
            </button>
        </div>
    </mat-dialog-actions>
</div>