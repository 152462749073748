import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "app/guard/auth-guard.service";
import { BiddingBiddingComponent } from "./biddings/bidding-biddings/bidding-bidding.component";
import { GeneralBiddingComponent } from "./biddings/general-biddings/general-biddings.component";
import { MarketBiddingComponent } from "./biddings/market-biddings/market-bidding.component";
import { WalletBiddingComponent } from "./biddings/wallet-biddings/wallet-bidding.component";
import { BiddingDashboardComponent } from "./dashboards/bidding-dashboard/bidding-dashboard.component";
import { GeneralDashboardComponent } from "./dashboards/general-dashboard/general-dashboard.component";
import { MarketDashboardComponent } from "./dashboards/market-dashboard/market-dashboard.component";
import { WalletDashboardComponent } from "./dashboards/wallet-dashboard/wallet-dashboard.component";
import { NotificationComponent } from "./notification/notification.component";

const pagesRoutes: Routes = [
    {
        path: "app",
        canActivate: [AuthGuardService],
        children: [
            {
                path: "general-dashboard",
                component: GeneralDashboardComponent,
            },
            {
                path: "wallet-dashboard",
                component: WalletDashboardComponent,
            },
            {
                path: "bidding-dashboard",
                component: BiddingDashboardComponent,
            },
            {
                path: "market-dashboard",
                component: MarketDashboardComponent,
            },
            {
                path: "general-bidding",
                component: GeneralBiddingComponent,
            },
            {
                path: "wallet-bidding",
                component: WalletBiddingComponent,
            },
            {
                path: "bidding-bidding",
                component: BiddingBiddingComponent,
            },
            {
                path: "market-bidding",
                component: MarketBiddingComponent,
            },
            {
                path: "notification",
                component: NotificationComponent,
            },
        ],
    },
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
