import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "wallet-dashboard",
        title: "Dashboard Cartera",
        type: "item",
        icon: "dashboard",
        url: "/app/wallet-dashboard",
    },
    {
        id: "bidding-dashboard",
        title: "Dashboard Licitación",
        type: "item",
        icon: "dashboard",
        url: "/app/bidding-dashboard",
    },
    {
        id: "market-dashboard",
        title: "Dashboard Mercado",
        type: "item",
        icon: "dashboard",
        url: "/app/market-dashboard",
    },
    {
        id: "wallet-bidding",
        title: "Lista Cartera",
        type: "item",
        icon: "assignment",
        url: "/app/wallet-bidding",
    },
    {
        id: "bidding-bidding",
        title: "Lista Licitaciones",
        type: "item",
        icon: "assignment",
        url: "/app/bidding-bidding",
    },
    {
        id: "market-bidding",
        title: "Lista Mercado",
        type: "item",
        icon: "assignment",
        url: "/app/market-bidding",
    },
    {
        id: "notification",
        title: "Notificaciones",
        type: "item",
        icon: "add_alert",
        url: "/app/notification",
    },
];

export const generalNavigation: FuseNavigation[] = [
    {
        id: "general-dashboard",
        title: "Dashboard Licitaciones",
        type: "item",
        icon: "dashboard",
        url: "/app/general-dashboard",
    },
    {
        id: "general-bidding",
        title: "Lista Licitaciones",
        type: "item",
        icon: "assignment",
        url: "/app/general-bidding",
    },
    {
        id: "notification",
        title: "Notificaciones",
        type: "item",
        icon: "add_alert",
        url: "/app/notification",
    },
];
