import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fuseAnimations } from "@fuse/animations";
import { REGIONS } from "app/shared/js/regions";
import {
    ALL_NOTIFICATION_TABLE_COLUMNS,
    DISPLAYED_NOTIFICATION_TABLE_COLUMNS,
} from "app/shared/js/tableColumns";
import { Region } from "app/shared/models/region.model";
import { FormatNumberPipe } from "app/shared/pipes/format-number.pipe";
import {
    MsgObject,
    NotificationService,
} from "app/shared/services/notification.service";
import { UserService } from "app/shared/services/user.service";
import { loadColorPalette } from "app/utils/colors";
import * as moment from "moment";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";

@Component({
    selector: "notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
    animations: fuseAnimations,
})
export class NotificationComponent {
    dataSource: MatTableDataSource<any>;
    dataSourceLength: number;
    pageSize: number = 10;
    isLoading: boolean = false;

    displayedColumns = DISPLAYED_NOTIFICATION_TABLE_COLUMNS;
    allColumns = ALL_NOTIFICATION_TABLE_COLUMNS;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    private _unsubscribeAll: Subject<any>;

    users: Array<any> = [];
    currentUser: any;

    regions: Array<Region> = REGIONS;

    notificationConfig: any;

    constructor(
        private _notificationService: NotificationService,
        public _dialog: MatDialog,
        private _userService: UserService,
        private _snackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
        loadColorPalette();
    }

    ngOnInit(): void {
        this.currentUser = JSON.parse(sessionStorage.getItem("user"));

        fromEvent(this.filter.nativeElement, "keyup")
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }

                this.dataSource.filter = this.filter.nativeElement.value;
            });

        this.getData();
    }

    getData() {
        this.isLoading = true;
        this._notificationService
            .getNotifications()
            .subscribe((response: any) => {
                this.isLoading = false;
                //TODO: verificar si hay que mantener este fix o corregirlo a nivel de back
                const fixDateResponse = response.map((item) => ({
                    ...item,
                    date: item.date.replace("T00", "T06"),
                }));
                this.dataSource = new MatTableDataSource(fixDateResponse);
                this.dataSourceLength = response.length;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            });

        this._userService.getUsers({}).subscribe((response: any) => {
            if (
                response !== undefined &&
                response !== null &&
                response.length > 0
            ) {
                this.users = response.filter(
                    (u: any) => u.id !== this.currentUser.id
                );
            }
        });

        this.getNotificationConfig();
    }

    getNotificationConfig() {
        this._notificationService
            .getNotificationConfig({})
            .subscribe((response: any) => {
                this.notificationConfig = response;
            });
    }

    sendNotification() {
        const dialogRef = this._dialog.open(NewNotificationComponent, {
            width: "50%",
            data: {
                users: this.users,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== "false" && result !== undefined) {
                const msgObject: MsgObject = {
                    id: result.id,
                    msg: result.msg,
                    users: result.users,
                    date: moment().utc().format(),
                };

                this._notificationService
                    .sendNotification(msgObject)
                    .subscribe(() => {
                        this.getData();
                    });
            }
        });
    }

    configNotifications() {
        const dialogRef = this._dialog.open(ConfigNotificationsComponent, {
            width: "50%",
            data: {
                regions: this.regions,
                notificationConfig: this.notificationConfig,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {

                if(result.deleteConfig){
                    this._notificationService.deleteNotificationConfig().subscribe(()=>{
                        this.getNotificationConfig();

                        this._snackBar.open(
                            "Se ha limpiado la configuración de notificaciones y no seguiran apareciendo nuevas.",
                            "Cerrar",
                            {
                                duration: 5000,
                            }
                        );
                    });
                }else{
                    // Crea o actualiza la configuración

                    const params = {
                        amount: typeof result.amount == "string" ? parseInt(result.amount.replace(/\./g, "")): result.amount,
                        userId: this.currentUser.id,
                        region: result.region
                    };
                    this._notificationService
                        .patchNotificationConfig(params)
                        .subscribe(() => {
                            this.getNotificationConfig();

                            this._snackBar.open(
                                "Se ha actualizado la configuración con éxito",
                                "Cerrar",
                                {
                                    duration: 5000,
                                }
                            );
                        });

                }
            }
        });
    }

    openMessage(msg) {
        this._dialog.open(OpenNotificationComponent, {
            width: "60%",
            data: {
                message: msg,
            },
        });
    }

    deleteMessage(notification){
        this.isLoading = true;
        this._notificationService.deleteNotification(notification)
        .subscribe((response)=>{
            this.isLoading = false;
            if(response){
                this.getData();
                
            }
            this._snackBar.open(
                response ? "Se ha eliminado la notificación exitosamente.":"Hubo un problema al eliminar la notificación",
                "Cerrar",
                {
                    duration: 5000,
                }
            );
        },(error)=>{
            this.isLoading = false;
            this._snackBar.open(
               "Hubo un problema al eliminar la notificación",
                "Cerrar",
                {
                    duration: 5000,
                }
            );
        });
    }
}

@Component({
    selector: "new-notification",
    templateUrl: "dialog/new-notification.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class NewNotificationComponent {
    newNotificationForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<NewNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder
    ) {
        this.newNotificationForm = this._formBuilder.group({
            users: [[], [Validators.required]],
            msg: ["", [Validators.required, Validators.minLength(10)]],
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: "config-notifications",
    templateUrl: "dialog/config-notifications.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class ConfigNotificationsComponent {
    configNotificationForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfigNotificationsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private _formatNumberPipe: FormatNumberPipe
    ) {
        this.configNotificationForm = this._formBuilder.group({
            amount: [0, []],
            region: [data.regions[0].value, []],
        });

        if (
            data.notificationConfig !== undefined &&
            data.notificationConfig !== null
        ) {
            this.configNotificationForm.setValue({
                amount: data.notificationConfig.amount,
                region: data.notificationConfig.region,
            });

            this.formatNumber();
        }
    }

    formatNumber() {
        let formVal: number = 0;
        if (
            typeof this.configNotificationForm.controls["amount"].value ===
            "string"
        ) {
            formVal = parseInt(
                this.configNotificationForm.controls["amount"].value.replace(
                    /\./g,
                    ""
                )
            );
        } else if (
            typeof this.configNotificationForm.controls["amount"].value ===
            "number"
        ) {
            formVal = this.configNotificationForm.controls["amount"].value;
        }
        const tVal = this._formatNumberPipe.transform(
            isNaN(formVal) ? 0 : formVal
        );
        this.configNotificationForm.controls["amount"].setValue(tVal);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: "open-notification",
    templateUrl: "dialog/open-notification.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class OpenNotificationComponent {
    constructor(
        public dialogRef: MatDialogRef<OpenNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
