import { NgModule } from '@angular/core';

import { BiddingBiddingComponent } from './bidding-bidding.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { BiddingService } from 'app/shared/services/bidding.service';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { BiddingOriginPipe } from 'app/shared/pipes/bidding-origin.pipe';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BiddingColumnsPipe } from 'app/shared/pipes/bidding-columns.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    declarations: [
        BiddingBiddingComponent,
    ],
    entryComponents: [
    ],
    providers: [
        BiddingService,
        BiddingOriginPipe,
        BiddingColumnsPipe
    ],
    imports: [
        //Material
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSortModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatDatepickerModule,

        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule,
        FuseSidebarModule,

        NgxChartsModule,
        NgxDaterangepickerMd.forRoot(),
        
        PipesModule

    ],
    exports: [
        BiddingBiddingComponent
    ]
})
export class BiddingBiddingModule {} 