import { Component, ViewChild, ElementRef  } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { BarVerticalComponent, PieChartComponent } from "@swimlane/ngx-charts";
import { RANGES } from "app/shared/js/pickerRanges";
import { REGIONS } from "app/shared/js/regions";
import { FORM_END_VALUE } from "app/shared/js/startValues";
import { Region } from "app/shared/models/region.model";
import { BiddingRegionPipe } from "app/shared/pipes/bidding-region.pipe";
import { FormatNumberPipe } from "app/shared/pipes/format-number.pipe";
import { WalletDashboardService } from "app/shared/services/wallet-dashboars.service";
import { ColorPalette, loadColorPalette } from "app/utils/colors";
import { getUserData } from "app/utils/navigationUtils";
import * as shape from "d3-shape";
import * as _ from "lodash";
import * as moment from "moment";
import SvgSaver from "svgsaver";
import { MillionPipe } from "app/shared/pipes/million.pipe";


@Component({
    selector: "wallet-dashboard",
    templateUrl: "./wallet-dashboard.component.html",
    styleUrls: ["./wallet-dashboard.component.scss"],
})
export class WalletDashboardComponent {
    amountPerRegionGraph: any = {};
    amountPerCategoryGraph: any = {};
    top10Graph: any = {};

    amountPerRegionGraphData: Array<any> = [];
    amountPerCategoryData: Array<any> = [];
    top10GraphData: Array<any> = [];

    dashboardFilter: any = {};

    form: FormGroup;
    userData: any = {};
    regions: Array<Region> = REGIONS;
    pickerRanges: object = RANGES;

    maxBidding: any = null;
    biddingCount: any = null;
    biddingsPerState: any = null;
    biddingsPerStateKeys: any = null;
    biddingsPerRegion: any = null;
    biddingsPerRegionKeys: any = null;
    biddingsPerRegionRegions: any = null;
    colorPalette: ColorPalette;

    biddingsPerTypology: any = null;

    providers: Array<string> = [];

    selectedRegion: string = "Todas las regiones";
    selectedCurrency: number = 0;

    loading = {
        amountPerRegionGraph: true,
        top10Graph: true,
        maxAmount: true,
        biddingCount: true,
        amountPerCategory: true,
    };

    // Referencias usadas para exportar pdf
    @ViewChild('regionChart',{static:false}) regionChart: ElementRef;
    @ViewChild('top10Chart',{static:false}) top10Chart: ElementRef;
    @ViewChild('categoryChart',{static:false}) categoryChart: ElementRef;

    yAxisTickFormatting = (value) =>`${this._formatNumberPipe.transform(this._millionPipe.transform(value),value)}`;
    yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

    routeSub: any;
    defaultFormFields: any;

    svgSaver = new SvgSaver();

    @ViewChild("amountPerCategory", { static: true })
    amountPerCategory: PieChartComponent;

    @ViewChild("amountPerRegion", { static: true })
    amountPerRegion: BarVerticalComponent;

    @ViewChild("top10", { static: true })
    top10: BarVerticalComponent;

    temporalFilter: any = null;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _formBuilder: FormBuilder,
        private _walletDashboardService: WalletDashboardService,
        private _biddingRegionPipe: BiddingRegionPipe,
        private _formatNumberPipe: FormatNumberPipe,
        private _millionPipe: MillionPipe,
        private _router: Router
    ) {
        this.colorPalette = loadColorPalette();
    }

    ngOnInit(): void {
        this.routeSub = this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.urlAfterRedirects !== "/app/wallet-dashboard") {
                    sessionStorage.setItem(
                        "wallet-dashboard-filter",
                        JSON.stringify(this.dashboardFilter)
                    );
                    sessionStorage.setItem(
                        "wallet-dashboard-form-fields",
                        JSON.stringify(this.defaultFormFields)
                    );
                }
            }
        });

        this.userData = getUserData()
        this.form = this._formBuilder.group({
            startDate: new FormControl(moment().subtract(1, "year"), []),
            endDate: new FormControl(moment(), []),
            sign: new FormControl(0, []),
            currency: new FormControl(0, []),
            startAmount: new FormControl(0),
            endAmount: new FormControl(FORM_END_VALUE),
            region: new FormControl(this.regions[0].value, []),
        });

        this.amountPerRegionGraph = {
            currentRange: "",
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: "Days",
            showYAxisLabel: false,
            yAxisLabel: "Isues",
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
            },
            onSelect: (ev) => {
                this.startLoading();
                const filter = _.cloneDeep(this.dashboardFilter);
                const keyArray = filter.where.and.map((o) => Object.keys(o)[0]);
                if (!keyArray.includes("region")) {
                    const reg = this._biddingRegionPipe.reverseTransform(
                        ev.name
                    );
                    filter.where.and.push({ region: reg });
                    this.form.controls["region"].setValue(reg);
                }
                this.selectedRegion = ev.name;
                if (this.temporalFilter !== null) this.handleTempFilter(filter);
                else {
                    this.temporalFilter = filter;
                    this.getData(filter);
                }
            },
            supporting: {
                currentRange: "",
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: "Days",
                showYAxisLabel: false,
                yAxisLabel: "Isues",
                scheme: {
                    domain: this.colorPalette.charts,
                },
                curve: shape.curveBasis,
            },
        };

        this.top10Graph = {
            currentRange: "",
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: "Days",
            showYAxisLabel: false,
            yAxisLabel: "Isues",
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
            },
            onSelect: (ev) => {},
            supporting: {
                currentRange: "",
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: "Days",
                showYAxisLabel: false,
                yAxisLabel: "Isues",
                scheme: {
                    domain: this.colorPalette.charts,
                },
                curve: shape.curveBasis,
            },
        };

        this.amountPerCategoryGraph = {
            legend: true,
            explodeSlices: false,
            labels: true,
            doughnut: false,
            gradient: false,
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
            },
            onSelect: (ev) => {
                this.startLoading();
                const filter = _.cloneDeep(this.dashboardFilter);
                

                if (typeof ev === "string") {
                    filter.where.and.push({ categoryName: ev });
                } else {
                    filter.where.and.push({ categoryName: ev.name });
                }

                if (this.temporalFilter !== null){
                    this.handleTempFilter(filter);
                }
                else {
                    this.temporalFilter = filter;
                    this.getData(filter);
                }
            },
        };

        if (
            sessionStorage.getItem("wallet-dashboard-filter") !== null &&
            JSON.parse(sessionStorage.getItem("wallet-dashboard-filter")) !==
                null
        ) {
            this.dashboardFilter = JSON.parse(
                sessionStorage.getItem("wallet-dashboard-filter")
            );
            this.getData(this.dashboardFilter);
            if (
                sessionStorage.getItem("wallet-dashboard-form-fields") !==
                "undefined"
            ) {
                this.defaultFormFields = JSON.parse(
                    sessionStorage.getItem("wallet-dashboard-form-fields")
                );
                this.setFilterDefaultValues(this.defaultFormFields);
            }
        } else {
            this.submit();
        }
    }

    ngAfterContentInit() {
        this.formatNumber("startAmount");
        this.formatNumber("endAmount");
    }

    startLoading() {
        this.loading.amountPerRegionGraph = true;
        this.loading.top10Graph = true;
        this.loading.maxAmount = true;
        this.loading.biddingCount = true;
        this.loading.amountPerCategory = true;
    }

    handleTempFilter(newFilter: any) {
        const newFilterKeyArray = newFilter.where.and.map(
            (o) => Object.keys(o)[0]
        );
        const oldFilterKeyArray = this.temporalFilter.where.and.map(
            (o) => Object.keys(o)[0]
        );
        const diffEle = newFilterKeyArray.filter(
            (x) => !oldFilterKeyArray.includes(x)
        );
        if (diffEle.length > 0) {
            const index = newFilterKeyArray.indexOf(diffEle[0]);
            this.temporalFilter.where.and.push(newFilter.where.and[index]);
            this.getData(this.temporalFilter);
        } else {
            this.getData(this.temporalFilter);
        }
    }

    setFilterDefaultValues(formFields: any) {
        formFields.endDate = moment(formFields.endDate).utc();
        formFields.startDate = moment(formFields.startDate).utc();
        this.form.patchValue(formFields);
    }

    getData(filter: any): void {
        this.selectedRegion = this._biddingRegionPipe.transform(
            this.form.value.region
        );

        try {
            this._walletDashboardService
                .getBiddingWithMaxAmount(filter)
                .subscribe((response) => {
                    //console.log(response);
                    console.log("(response) 👉", response);
                    this.loading.maxAmount = false;
                    this.maxBidding = response;
                });
        } catch (error) {
            console.log("(error) 👉", error);
        }

        this._walletDashboardService
            .getBiddingCount(filter, this.selectedCurrency)
            .subscribe((response) => {
                //console.log(response);
                this.loading.biddingCount = false;
                this.biddingCount = response;
            });

        //Pie
        this._walletDashboardService
            .getBiddingPerTypology(filter, this.selectedCurrency)
            .subscribe((response: any) => {
                //console.log(response);
                this.loading.amountPerCategory = false;
                this.amountPerCategoryData = response;
            });

        this._walletDashboardService
            .getBiddingAmountPerRegion(filter, this.selectedCurrency)
            .subscribe((response) => {
                //console.log(response);
                this.biddingsPerRegion = response;
                this.biddingsPerRegionRegions = Object.keys(
                    this.biddingsPerRegion
                );

                if(this.biddingsPerRegionRegions.length > 0){
                    this.biddingsPerRegionKeys = this.biddingsPerRegionRegions.length
                        ? Object.keys(
                            this.biddingsPerRegion[
                                this.biddingsPerRegionRegions[0]
                            ]
                        )
                        : 0;
                    this.amountPerRegionGraph.selectedKey = this
                        .biddingsPerRegionRegions.length
                        ? Object.keys(
                            this.biddingsPerRegion[
                                this.biddingsPerRegionRegions[0]
                            ]
                        )[0]
                        : "";
                    this.amountPerRegionGraphData =
                        this.biddingsPerRegionRegions.map((region: any) => {
                            return {
                                name: region,
                                value: this.biddingsPerRegion[region][
                                    this.amountPerRegionGraph.selectedKey
                                ],
                            };
                        });
                }else{
                    this.amountPerRegionGraphData = [];
                }
                this.loading.amountPerRegionGraph = false;
            });

        this.getTop10(filter);
    }

    onBarKeyChange(value: any) {
        this.amountPerRegionGraphData = this.biddingsPerRegionRegions.map(
            (region: any) => {
                return {
                    name: region,
                    value: this.biddingsPerRegion[region][value],
                };
            }
        );
    }

    getTop10(filter: any) {
        this._walletDashboardService
            .getTop10(filter, this.selectedCurrency)
            .subscribe((response: any) => {
                this.loading.top10Graph = false;
                this.top10GraphData = response;
            });
    }

    /**
     * Submit form action
     *
     * @param null
     */
    submit(): void {
        this.loading.amountPerRegionGraph = true;
        this.loading.top10Graph = true;
        this.loading.maxAmount = true;
        this.loading.biddingCount = true;

        this.selectedCurrency = this.form.value.currency;

        this.dashboardFilter.where = { and: [] };
        this.dashboardFilter.order = "publicationDate DESC";

        if (
            this.form.value.startDate !== null &&
            this.form.value.endDate !== null
        ) {
            if (typeof this.form.value.endDate === "string") {
                this.dashboardFilter.where.and.push({
                    publicationDate: { lte: this.form.value.endDate },
                });
                this.dashboardFilter.where.and.push({
                    publicationDate: { gte: this.form.value.startDate },
                });
            } else {
                this.dashboardFilter.where.and.push({
                    publicationDate: {
                        lte: this.form.value.endDate
                            .utc()
                            .endOf("day")
                            .format(),
                    },
                });
                this.dashboardFilter.where.and.push({
                    publicationDate: {
                        gte: this.form.value.startDate
                            .utc()
                            .startOf("day")
                            .format(),
                    },
                });
            }
        }

        if (
            this.form.value.sign !== null &&
            this.form.value.startAmount !== null &&
            this.form.value.startAmount !== "" &&
            this.form.value.endAmount !== null &&
            this.form.value.endAmount !== ""
        ) {
            const startAmount =
                typeof this.form.value.startAmount == "string"
                    ? parseInt(this.form.value.startAmount.replace(/\./g, ""))
                    : this.form.value.startAmount;
            const endAmount =
                typeof this.form.value.endAmount == "string"
                    ? parseInt(this.form.value.endAmount.replace(/\./g, ""))
                    : this.form.value.endAmount;

            if (this.selectedCurrency === 0) {
                this.dashboardFilter.where.and.push({
                    estimatedAmount: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmount: { lte: endAmount },
                });
            } else if (this.selectedCurrency === 1) {
                this.dashboardFilter.where.and.push({
                    estimatedAmountDolar: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmountDolar: { lte: endAmount },
                });
            } else if (this.selectedCurrency === 2) {
                this.dashboardFilter.where.and.push({
                    estimatedAmountUf: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmountUf: { lte: endAmount },
                });
            }
        }

        if (this.form.value.region !== null && this.form.value.region !== 0) {
            this.dashboardFilter.where.and.push({
                region: this.form.value.region,
            });
        }

        if(this.userData && this.userData.organizationCode === "ferrovial"){
            this.dashboardFilter.where.and.push({ dashboard: 2 });
        }

        this.setDefaultFormFields();
        this.getData(this.dashboardFilter);
    }

    setDefaultFormFields() {
        this.defaultFormFields = {
            endDate: this.form.value.endDate,
            startDate: this.form.value.startDate,
            sign: this.form.value.sign,
            currency: this.form.value.currency,
            startAmount: this.form.value.startAmount,
            endAmount: this.form.value.endAmount,
            region: this.form.value.region,
        };
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    formatNumber(val: string) {
        let formVal: number = 0;
        if (typeof this.form.controls[val].value === "string") {
            formVal = parseInt(
                this.form.controls[val].value.replace(/\./g, "")
            );
        } else if (typeof this.form.controls[val].value === "number") {
            formVal = this.form.controls[val].value;
        }
        const tVal = this._formatNumberPipe.transform(
            isNaN(formVal) ? 0 : formVal
        );
        this.form.controls[val].setValue(tVal);
    }

    clearFilter() {
        this.temporalFilter = null;

        this.form.setValue({
            endDate: moment(),
            startDate: moment().subtract(1, "year"),
            sign: 0,
            currency: 0,
            startAmount: 0,
            endAmount: FORM_END_VALUE,
            region: this.regions[0].value,
        });

        this.formatNumber("startAmount");
        this.formatNumber("endAmount");

        this.submit();
    }
}
