import { NgModule } from '@angular/core';
import { BiddingColumnsPipe } from './bidding-columns.pipe';
import { BiddingOriginPipe } from './bidding-origin.pipe';
import { BiddingRegionPipe } from './bidding-region.pipe';
import { CurrencyTypePipe } from './currency-type.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { MillionPipe } from './million.pipe';
import { OriginVariablesPipe } from './origin-variables.pipe';

@NgModule({
    imports: [],
    declarations: [
        BiddingColumnsPipe, 
        BiddingOriginPipe, 
        BiddingRegionPipe, 
        OriginVariablesPipe,
        FormatNumberPipe,
        CurrencyTypePipe,
        MillionPipe
    ],
    exports: [
        BiddingColumnsPipe, 
        BiddingOriginPipe, 
        BiddingRegionPipe, 
        OriginVariablesPipe,
        FormatNumberPipe,
        CurrencyTypePipe,
        MillionPipe
    ]
})
export class PipesModule {}