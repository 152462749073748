
<div id="market-dashboard" class="page-layout blank">
    <div class="content">
        <div class="left">
            <!-- 
                ROW OF CARDS 
            -->
            <div
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="start start"
            >
                <!-- Widget 1 -->
                <div
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-md="25"
                >
                    <div
                        class="fuse-card auto-width mb-16"
                        [ngClass.gt-sm]="'mr-16'"
                    >
                        <div
                            class="p-16 pb-0 border-bottom"
                            fxLayout="row wrap"
                            fxLayoutAlign="center center"
                        >
                            <span class="h2 mb-16"
                                >Licitación con mayor monto ({{
                                    selectedCurrency | CurrencyType
                                }})</span
                            >
                        </div>

                        <div class="h-296 mb-16">
                            <mat-progress-bar
                                *ngIf="loading.maxAmount"
                                [color]="'warn'"
                                mode="indeterminate"
                            >
                            </mat-progress-bar>
                            <div
                                class="pt-8 pb-32"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                                *ngIf="!loading.maxAmount"
                            >
                                <div
                                    class="
                                        indicator-color
                                        font-size-40
                                        line-height-72
                                    "
                                    [style.color]="colorPalette.primary"
                                >
                                    <span *ngIf="selectedCurrency === 0">{{
                                        maxBidding == null
                                            ? "--"
                                            : (maxBidding.estimatedAmount
                                              | million: selectedCurrency
                                              | formatNumber
                                                  : maxBidding.estimatedAmount)
                                    }}</span>
                                    <span *ngIf="selectedCurrency === 1">{{
                                        maxBidding == null
                                            ? "--"
                                            : (maxBidding.estimatedAmountDolar
                                              | million: selectedCurrency
                                              | formatNumber
                                                  : maxBidding.estimatedAmountDolar)
                                    }}</span>
                                    <span *ngIf="selectedCurrency === 2">{{
                                        maxBidding == null
                                            ? "--"
                                            : (maxBidding.estimatedAmountUf
                                              | million: selectedCurrency
                                              | formatNumber
                                                  : maxBidding.estimatedAmountUf)
                                    }}</span>
                                </div>
                                <div
                                    class="
                                        h3
                                        secondary-text
                                        font-size-15 font-weight-500
                                        pl-20
                                        pr-20
                                        mb-20
                                    "
                                >
                                    {{
                                        maxBidding == null
                                            ? "--"
                                            : maxBidding.name
                                    }}
                                </div>
                                <ul class="mt-16 font-weight-500">
                                    <li class="m-4">
                                        <strong>Código:</strong>
                                        {{
                                            maxBidding == null
                                                ? "--"
                                                : maxBidding.code
                                        }}
                                    </li>
                                    <li class="m-4">
                                        <strong>Estado:</strong>
                                        {{
                                            maxBidding == null
                                                ? "--"
                                                : maxBidding.status
                                        }}
                                    </li>
                                    <li class="m-4">
                                        <strong>Región:</strong>
                                        {{
                                            maxBidding == null
                                                ? "--"
                                                : (maxBidding.region
                                                  | biddingRegion)
                                        }}
                                    </li>
                                    <li class="m-4">
                                        <strong>Origen:</strong>
                                        {{
                                            maxBidding == null
                                                ? "--"
                                                : (maxBidding.origin
                                                  | biddingOrigin)
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Widget 1 -->
                <!-- Widget 2 -->
                <div
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-md="25"
                >
                    <div
                        class="fuse-card auto-width mb-16"
                        [ngClass.gt-sm]="'mr-16'"
                    >
                        <div
                            class="p-16 pb-0 border-bottom"
                            fxLayout="row wrap"
                            fxLayoutAlign="center center"
                        >
                            <span class="h2 mb-16"
                                >Monto promedio ({{
                                    selectedCurrency | CurrencyType
                                }})</span
                            >
                        </div>

                        <div class="h-296 mb-16">
                            <mat-progress-bar
                                *ngIf="loading.biddingCount"
                                [color]="'warn'"
                                mode="indeterminate"
                            >
                            </mat-progress-bar>
                            <div
                                class="pt-8 pb-32"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                                *ngIf="!loading.biddingCount"
                            >
                                <div
                                    class="
                                        indicator-color
                                        font-size-28
                                        line-height-72
                                    "
                                    [style.color]="colorPalette.primary"
                                >
                                    {{
                                        biddingCount == null
                                            ? ""
                                            : (biddingCount.totalAverage
                                              | million: selectedCurrency
                                              | formatNumber
                                                  : biddingCount.totalAverage)
                                    }}
                                </div>
                                <div
                                    class="
                                        h3
                                        secondary-text
                                        font-size-15 font-weight-500
                                        pl-20
                                        pr-20
                                        mb-20
                                    "
                                >
                                    {{
                                        biddingCount == null
                                            ? ""
                                            : "De un total de: " +
                                              (biddingCount.total
                                                  | formatNumber) +
                                              " Licitaciones"
                                    }}
                                </div>
                                <ul class="mt-16 font-weight-500">
                                    <li class="m-4">
                                        <strong>Metro:</strong>
                                        {{
                                            biddingCount == null ||
                                            biddingCount.total === 0
                                                ? "0"
                                                : biddingCount.subway +
                                                  " (" +
                                                  ((biddingCount.subway * 100) /
                                                      biddingCount.total
                                                      | number: "1.0-0") +
                                                  "%)"
                                        }}
                                    </li>
                                    <li class="m-4">
                                        <strong>Mercado público:</strong>
                                        {{
                                            biddingCount == null ||
                                            biddingCount.total === 0
                                                ? "0"
                                                : biddingCount.publicMarket +
                                                  " (" +
                                                  ((biddingCount.publicMarket *
                                                      100) /
                                                      biddingCount.total
                                                      | number: "1.0-0") +
                                                  "%)"
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Widget 2 -->
                <!-- Widget 3 -->
                <div class="widget" fxFlex="100" fxFlex.gt-md="50">
                    <div
                        class="fuse-card auto-width mb-16"
                        [ngClass.gt-sm]="'mb-0'"
                    >
                        <div
                            class="p-16 pb-0 border-bottom"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <span class="h2 mb-16"
                                #categoryChartTitle
                                >Monto licitaciones por categoría ({{
                                    selectedCurrency | CurrencyType
                                }})</span
                            >
                            <div class="chart-img-download">
                                <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="
                                        downloadMenuAmountPerCategory
                                    "
                                    aria-label="more"
                                >
                                    <mat-icon>get_app</mat-icon>
                                </button>

                                <mat-menu
                                    #downloadMenuAmountPerCategory="matMenu"
                                >
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="
                                            svgSaver.asSvg(amountPerCategory)
                                        "
                                        mat-menu-item
                                    >
                                        SVG
                                    </button>
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="
                                            svgSaver.asPng(amountPerCategory)
                                        "
                                        mat-menu-item
                                    >
                                        PNG
                                    </button>
                                </mat-menu>
                            </div>
                            <!-- <div class="chart-img-download">
                                <a class="cursor-pointer mr-16" (click)="svgSaver.asSvg(amountPerCategory)">SVG</a>
                                <a class="cursor-pointer" (click)="svgSaver.asPng(amountPerCategory)">PNG</a>
                            </div> -->
                        </div>

                        <div
                            fxLayout="row wrap"
                            fxLayoutAlign="start start"
                            #amountPerCategory
                        >
                            <div
                                class="h-296 mb-16"
                                fxLayout="row"
                                fxFlex="100"
                            >
                                <mat-progress-bar
                                    *ngIf="loading.amountPerCategory"
                                    [color]="colorPalette.secondary"
                                    mode="indeterminate"
                                >
                                </mat-progress-bar>

                                <div
                                    class="w-100-p"
                                    *ngIf="!loading.amountPerCategory"
                                    #categoryChart
                                >
                                    <ngx-charts-pie-chart
                                        
                                        *fuseIfOnDom
                                        [scheme]="amountPerCategoryGraph.scheme"
                                        [results]="amountPerCategoryData"
                                        [legend]="amountPerCategoryGraph.legend"
                                        [explodeSlices]="
                                            amountPerCategoryGraph.explodeSlices
                                        "
                                        [labels]="amountPerCategoryGraph.labels"
                                        [doughnut]="
                                            amountPerCategoryGraph.doughnut
                                        "
                                        [gradient]="
                                            amountPerCategoryGraph.gradient
                                        "
                                        [maxLabelLength]="20"
                                        (select)="
                                            amountPerCategoryGraph.onSelect(
                                                $event
                                            )
                                        "
                                    >
                                        <ng-template
                                            #tooltipTemplate
                                            let-model="model"
                                        >
                                            <div class="tooltip">
                                                <h4 class="m-4">
                                                    {{ model.name }}
                                                </h4>
                                                <h4 class="m-4">
                                                    {{
                                                        model.value
                                                            | million
                                                                : selectedCurrency
                                                            | formatNumber
                                                                : model.value
                                                    }}
                                                </h4>
                                            </div>
                                        </ng-template>
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Widget 3 -->
            </div>
            <!-- 
                / ROW OF CARDS 
            -->

            <!-- 
                ROW OF CARDS 
            -->
            <div
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="start start"
            >
                <!-- Widget 5 -->
                <div class="widget" fxFlex="100" fxFlex.md="50">
                    <div
                        class="fuse-card auto-width mb-16"
                        [ngClass.gt-sm]="'mb-0 mr-16'"
                        
                    >
                        <div
                            class="p-16 border-bottom"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <span class="h2"
                                #regionChartTitle
                                >Monto promedio por región ({{
                                    selectedCurrency | CurrencyType
                                }})</span
                            >
                            <div class="chart-img-download">
                                <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="
                                        downloadMenuAmountPerRegion
                                    "
                                    aria-label="more"
                                >
                                    <mat-icon>get_app</mat-icon>
                                </button>

                                <mat-menu
                                    #downloadMenuAmountPerRegion="matMenu"
                                >
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="
                                            svgSaver.asSvg(amountPerRegion)
                                        "
                                        mat-menu-item
                                    >
                                        SVG
                                    </button>
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="
                                            svgSaver.asPng(amountPerRegion)
                                        "
                                        mat-menu-item
                                    >
                                        PNG
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <div
                            fxLayout="row wrap"
                            fxLayoutAlign="start start"
                            #amountPerRegion
                            
                        >
                            <div
                                class="h-296 mb-16"
                                fxLayout="row"
                                fxFlex="100"
                            >
                                <mat-progress-bar
                                    *ngIf="loading.amountPerRegionGraph"
                                    [color]="'warn'"
                                    mode="indeterminate"
                                >
                                </mat-progress-bar>
                                <div
                                    class="w-100-p"
                                    *ngIf="!loading.amountPerRegionGraph"
                                    #regionChart
                                >
                                    <ngx-charts-bar-vertical
                                        *fuseIfOnDom
                                        [scheme]="amountPerRegionGraph.scheme"
                                        [results]="amountPerRegionGraphData"
                                        [gradient]="
                                            amountPerRegionGraph.gradient
                                        "
                                        [xAxis]="amountPerRegionGraph.xAxis"
                                        [yAxis]="amountPerRegionGraph.yAxis"
                                        [legend]="amountPerRegionGraph.legend"
                                        [showXAxisLabel]="
                                            amountPerRegionGraph.showXAxisLabel
                                        "
                                        [showYAxisLabel]="
                                            amountPerRegionGraph.showYAxisLabel
                                        "
                                        [xAxisLabel]="
                                            amountPerRegionGraph.xAxisLabel
                                        "
                                        [yAxisLabel]="
                                            amountPerRegionGraph.yAxisLabel
                                        "
                                        [yAxisTickFormatting]="
                                            yAxisTickFormattingFn
                                        "
                                        (select)="
                                            amountPerRegionGraph.onSelect(
                                                $event
                                            )
                                        "
                                    >
                                        <ng-template
                                            #tooltipTemplate
                                            let-model="model"
                                        >
                                            <div class="tooltip">
                                                <h4 class="m-4">
                                                    {{ model.name }}
                                                </h4>
                                                <h4 class="m-4">
                                                    {{
                                                        model.value
                                                            | million
                                                                : selectedCurrency
                                                            | formatNumber
                                                                : model.value
                                                    }}
                                                </h4>
                                            </div>
                                        </ng-template>
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Widget 5 -->
                <!-- Widget 6 -->
                <div class="widget" fxFlex="100" fxFlex.md="50">
                    <div
                        class="fuse-card auto-width mb-16"
                        [ngClass.gt-sm]="'mb-0'"
                    >
                        <div
                            class="p-16 border-bottom"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <span class="h2"
                                #top10ChartTitle
                                >Top 10 monto licitaciones ({{
                                    selectedRegion
                                }}) [{{
                                    selectedCurrency | CurrencyType
                                }}]</span
                            >
                            <div
                                class="chart-img-download"
                                *ngIf="!loading.top10Graph"
                            >
                                <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="downloadMenuTop10"
                                    aria-label="more"
                                >
                                    <mat-icon>get_app</mat-icon>
                                </button>

                                <mat-menu #downloadMenuTop10="matMenu">
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="svgSaver.asSvg(top10)"
                                        mat-menu-item
                                    >
                                        SVG
                                    </button>
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        (click)="svgSaver.asPng(top10)"
                                        mat-menu-item
                                    >
                                        PNG
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div
                            fxLayout="row wrap"
                            fxLayoutAlign="start start"
                            #top10

                            
                        >
                            <div
                                class="h-296 mb-16"
                                fxLayout="row"
                                fxFlex="100"
                            >
                                <mat-progress-bar
                                    *ngIf="loading.top10Graph"
                                    [color]="'warn'"
                                    mode="indeterminate"
                                >
                                </mat-progress-bar>
                                <div
                                    class="w-100-p"
                                    *ngIf="!loading.top10Graph"
                                    #top10Chart
                                >
                                    <ngx-charts-bar-vertical
                                        *fuseIfOnDom
                                        [scheme]="top10Graph.scheme"
                                        [results]="top10GraphData"
                                        [gradient]="top10Graph.gradient"
                                        [xAxis]="top10Graph.xAxis"
                                        [yAxis]="top10Graph.yAxis"
                                        [legend]="top10Graph.legend"
                                        [showXAxisLabel]="
                                            top10Graph.showXAxisLabel
                                        "
                                        [showYAxisLabel]="
                                            top10Graph.showYAxisLabel
                                        "
                                        [xAxisLabel]="top10Graph.xAxisLabel"
                                        [yAxisLabel]="top10Graph.yAxisLabel"
                                        [yAxisTickFormatting]="
                                            yAxisTickFormattingFn
                                        "
                                        (select)="top10Graph.onSelect($event)"
                                    >
                                        <ng-template
                                            #tooltipTemplate
                                            let-model="model"
                                        >
                                            <div class="tooltip">
                                                <h4 class="m-4">
                                                    {{ model.name }}
                                                </h4>
                                                <h4 class="m-4">
                                                    {{
                                                        model.value
                                                            | million
                                                                : selectedCurrency
                                                            | formatNumber
                                                                : model.value
                                                    }}
                                                </h4>
                                            </div>
                                        </ng-template>
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Widget 6 -->
            </div>
            <!-- 
                / ROW OF CARDS 
            -->
        </div>
    </div>
</div>



<report-btn 
    *ngIf="!loading.amountPerRegionGraph && 
            !loading.top10Graph && 
            !loading.maxAmount && 
            !loading.biddingCount && 
            !loading.amountPerCategory"
    [form]="form"
    [maxBidding]="maxBidding"
    [selectedRegion]="selectedRegion"
    [biddingCount]="biddingCount"
    [regionChart]="regionChart"
    [top10Chart]="top10Chart"
    [categoryChart]="categoryChart"

></report-btn>




<button
    mat-icon-button
    class="
        filter-icon
        mat-elevation-z2
        market-dashboard-config-button
        right-side-panel
    "
    (click)="toggleSidebarOpen('marketDashboardConfig')"
>
    <img class="logo" src="assets/icons/filter.svg" />
</button>

<fuse-sidebar
    name="marketDashboardConfig"
    class="market-dashboard-config-sidebar"
    position="right"
    [invisibleOverlay]="true"
>
    <div class="market-dashboard-config-panel" fusePerfectScrollbar>
        <div class="header">
            <span class="title">Filtro dashboard licitaciones</span>

            <button
                mat-icon-button
                class="close-button"
                (click)="toggleSidebarOpen('marketDashboardConfig')"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!-- 
            FORM 
        -->
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="group">
                <!-- 
                    Date Field 
                -->
                <span class="mb-4"><strong>Fecha de referencia:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <input
                            formControlName="referenceDate"
                            matInput
                            [matDatepicker]="referenceDatepicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="referenceDatepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #referenceDatepicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- 
                    / Date Field 
                -->

                <!-- 
                    Date Field 
                -->
                <span class="mb-4"><strong>Fecha de cierre:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <input
                            formControlName="closingDate"
                            matInput
                            [matDatepicker]="closingDatepicker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="closingDatepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #closingDatepicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- 
                    / Date Field 
                -->

                <span class="mb-4"><strong>Valores:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Moneda</mat-label>
                        <mat-select formControlName="currency">
                            <mat-option [value]="0"> CLP </mat-option>
                            <mat-option [value]="1"> US Dólar </mat-option>
                            <mat-option [value]="2"> UF </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- 
                    Estimated Amount Field 
                -->
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Monto inical</mat-label>
                        <input
                            matInput
                            formControlName="startAmount"
                            (keyup)="formatNumber('startAmount')"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Monto final</mat-label>
                        <input
                            matInput
                            formControlName="endAmount"
                            (keyup)="formatNumber('endAmount')"
                        />
                    </mat-form-field>
                </div>
                <!-- 
                    / Estimated Amount Field 
                -->

                <!-- 
                    Region Field 
                -->
                <span class="mb-4"><strong>Ubicación:</strong></span>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Región</mat-label>
                        <mat-select formControlName="region">
                            <mat-option
                                *ngFor="let region of regions"
                                [value]="region.value"
                            >
                                {{ region.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- 
                    / Region Field 
                -->

                <div fxLayout="row" fxLayoutAlign="center center">
                    <button
                        class="mt-16 w-60-p"
                        mat-raised-button
                        type="submit"
                        color="accent"
                    >
                        Filtrar
                    </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center">
                    <button
                        style="background-color: red"
                        class="mt-16 w-60-p"
                        mat-raised-button
                        (click)="clearFilter()"
                    >
                        Borrar filtro
                    </button>
                </div>
            </div>
        </form>
        <!-- 
            / END FORM 
        -->
    </div>
</fuse-sidebar>
