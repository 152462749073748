import { Region } from "../models/region.model";

export const REGIONS: Array<Region> = [
    {label: 'Todas las regiones', value: 0},
    {label: 'Región Tarapacá (I)', value: 1},
    {label: 'Región Antofagasta (II)', value: 2},
    {label: 'Región Atacama (III)', value: 3},
    {label: 'Región Coquimbo (IV)', value: 4},
    {label: 'Región Valparaíso (V)', value: 5},
    {label: 'Región O’Higgins (VI)', value: 6},
    {label: 'Región Maule (VII)', value: 7},
    {label: 'Región Bío-Bío (VIII)', value: 8},
    {label: 'Región La Araucanía (IX)', value: 9},
    {label: 'Región Los Lagos (X)', value: 10},
    {label: 'Región Aysén (XI)', value: 11},
    {label: 'Región Magallanes (XII)', value: 12},
    {label: 'Región Metropolitana (RM)', value: 13},
    {label: 'Región Los Ríos (XIV)', value: 14},
    {label: 'Región Arica y Parinacota (XV)', value: 15},
    {label: 'Región Ñuble (XVI)', value: 16},
    
];