<div class="notification-dialogs">
    <h1 mat-dialog-title class="new-notification-header">
        Mensaje
    </h1>
    <mat-dialog-content >
        <div [innerHTML]="data.message"></div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
        <div class="p-8 p-sm-8 ml-auto">
            <button mat-raised-button class="mr-8" mat-dialog-close="false">
                Cerrar
            </button>
        </div>
    </mat-dialog-actions>
</div>