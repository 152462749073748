import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private headers: HttpHeaders;

    constructor(
    ) {
    }

    getHeaders(): HttpHeaders {

        const token = sessionStorage.getItem('token');
        
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json;charset=utf-8')
            .set('Authorization', 'Bearer ' + token)
            //.set('Connection', 'keep-alive')
        ;
        return this.headers;
    }

}