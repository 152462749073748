
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'OriginVariables',
    pure: false
})
export class OriginVariablesPipe implements PipeTransform {

    transform(origin: string): any {
        if (origin === undefined) {
            return origin;
        }
    
        switch (origin) {
            case 'publicMarket':
                return 'Mercado público'
            case 'subway':
                return 'Metro'
            case 'seia':
                return 'SEIA'
            default:
                return origin;
        }
    }

}





