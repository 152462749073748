import { Pipe, PipeTransform } from '@angular/core';

export const transformOrigin = (origin: string): any => {
    if (origin === undefined) {
        return origin;
    }

    switch (origin) {
        case 'PUBLIC_MARKET':
            return 'Mercado público'
        case 'SUBWAY':
            return 'Metro'
        case 'SEA_GOB':
            return 'SEIA'
        default:
            return origin;
    }
};

@Pipe({
    name: 'biddingOrigin',
    pure: false
})
export class BiddingOriginPipe implements PipeTransform {

    transform(origin: string): any {
        return transformOrigin(origin);
    }

    reverseTransform(origin: string): any {
        if (origin === undefined) {
            return origin;
        }

        switch (origin.toLocaleLowerCase()) {
            case 'mercado':
                return 'PUBLIC_MARKET'
            case 'mercado público':
                return 'PUBLIC_MARKET'
            case 'metro':
                return 'SUBWAY'
            case 'seia':
                return 'SEA_GOB'
            default:
                return origin;
        }
    }
}