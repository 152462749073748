import { Component, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { currencyTypeTransform, CurrencyTypePipe } from 'app/shared/pipes/currency-type.pipe';
import { jsPDF } from 'jspdf';
import * as moment from "moment";
import { BiddingRegionPipe } from 'app/shared/pipes/bidding-region.pipe';
import { BiddingOriginPipe } from 'app/shared/pipes/bidding-origin.pipe';
import { transformNumberFormat, FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { transformMillion } from 'app/shared/pipes/million.pipe';
import { environment } from 'environments/environment';
import html2canvas from 'html2canvas';
import { FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MillionPipe } from 'app/shared/pipes/million.pipe';
import { ORIGINS } from 'app/utils/origins';

@Component({
    selector     : 'report-btn',
    templateUrl  : './report-btn.component.html',
    styleUrls    : ['./report-btn.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReportBtnComponent
{

    @Input() form:FormGroup;
    @Input() maxBidding:any;
    @Input() selectedRegion:string;
    @Input() biddingCount: any;
    @Input() regionChart: ElementRef;
    @Input() top10Chart: ElementRef;
    @Input() categoryChart: ElementRef;
    printing = false;

    /**
     * Constructor
     */
    constructor(
        private _biddingRegionPipe: BiddingRegionPipe,
        private _formatNumberPipe: FormatNumberPipe,
        private _decimalPipe: DecimalPipe,
        private _millionPipe: MillionPipe,
        private _currencyPipe: CurrencyTypePipe,
        private _regionPipe: BiddingRegionPipe,
        private _originPipe: BiddingOriginPipe
    )
    {
    }

    savePdf(){

        if(this.printing){
            //console.log('Ya está imprimiendo reporte');
            return;
        }

        //console.log('Imprimiendo reporte');
        //console.log('region',this.regionChart);
        //console.log('top 10',this.top10Chart);
        //console.log('category',this.categoryChart)

        this.printing = true;
        const currency = this._currencyPipe.transform(this.form.value.currency);

        //Tamaño de la hoja es 595 x 842
        let pdf = new jsPDF('p','pt','a4');

        // Declara opciones que se reutilizan más adelante
        const setTitleStyle = ()=>{
            pdf.setFontSize(16);
            pdf.setFont(undefined,'bold');
            pdf.setTextColor('#000000');
        };

        const setTitle2Style = () => {
            pdf.setFontSize(12);
            pdf.setFont(undefined,'bold');
            pdf.setTextColor('#303030');
        };

        const formatNumber = (v)=>{
            return this._formatNumberPipe.transform(this._millionPipe.transform(v),v);
        }

        const addImageContained = (url,format,x,y,cWidth,cHeight,options = {align:null}) => {
            const imageProps = pdf.getImageProperties(url);
            const { width, height} = imageProps;

            const ratioW = cWidth / width;
            const ratioH = cHeight / height;

            const ratio = ratioW < ratioH ? ratioW: ratioH;

            const nW = width*ratio;
            const nH = height*ratio;

            const {align = null} = options;
            let nX=x,nY=y;
            if(align == 'right'){
                nX= x + cWidth - nW;
            }
            if(align == 'center'){
                nX= x + cWidth/2 - nW/2;
                nY= y + cHeight/2 - nH/2;
            }

            
            pdf.addImage(url,format,nX,nY,nW,nH);
        };

        let x,y;
        const ml= 30;
        
        const canvasOptions = {
            backgroundColor: null,
            //windowWidth:1375,
            //windowHeight: 569,
            //windowWidth: 1900,
            //windowHeight: 1080,
            removeContainer: true,
            logging:true
        };

        // Inicia renderizado del pdf
        let filterHtml = `
            <p><b>Fecha de reporte:</b> ${moment().format('LLL')}</p>
            <p><b>Información de filtro:</b></p>
            <p><b>Rango de fechas:</b></p>
            <p>Desde ${moment(this.form.value.referenceDate).format('L')} hasta ${moment(this.form.value.closingDate).format('L')}</p>
            <p><b>Moneda:</b></p>
            <p>${currency}</p>
            <p><b>Monto inicial:</b></p>
            <p>${formatNumber(parseInt(this.form.value.startAmount))}</p>
            <p><b>Monto final:</b></p>
            <p>${formatNumber(parseInt(this.form.value.endAmount))}</p>
            <p><b>Ubicación:</b></p>
            <p>${this._regionPipe.transform(this.form.value.region)}</p>
        `;
        if(this.form.value.providerName && this.form.value.providerName.length > 0){
            filterHtml +=`
            <p><b>Adjudicatario</b><p>
            <p>${this.form.value.providerName}</p>
            `;
        }
        if(this.form.value.origin){
            const originMap = ORIGINS.reduce((c,v)=>{
                c[v.value] = v.label;
                return c;
            },{});

            filterHtml += `
            <p><b>Origen:</b></p>
            <p>${originMap[this.form.value.origin]}</p>
            `;

            if(this.form.value.status && this.form.value.status.length>0){
                const statusText = this.form.value.status.reduce((c,v)=>{
                    if(c.length>0){
                        c+='; ';
                    }
                    c+=v;
                    return c;
                },'');
                filterHtml +=`
                <p><b>Estado</b></p>
                <p>${statusText}</p>
                `
            }
        }
        let avgHtml='';
        let topHtml='';

        if(this.maxBidding){
            let amount: any = '--';
            if(this.maxBidding){
                switch(this.form.value.currency){
                    case 0:
                        amount = this.maxBidding.estimatedAmount;
                        break;
                    case 1:
                        amount = this.maxBidding.estimatedAmountDolar;
                        break;
                    case 2:
                        amount = this.maxBidding.estimatedAmountUf;
                        break;
                }
            }
            avgHtml=`
                <p class="title">Monto promedio (${currency})</p>
                <p class="indicator">${formatNumber(amount)}</p>
                <p>${this.maxBidding.name}</p>
                <p><b>Código:</b> ${this.maxBidding.code}</p>
                <p><b>Estado:</b> ${this.maxBidding.status}</p>
                <p><b>Región:</b> ${this._regionPipe.transform(this.maxBidding.region)}</p>
                <p><b>Origen:</b> ${this._originPipe.transform(this.maxBidding.origin)}</p>
            `;
        }else{
            avgHtml=`
                <h1 class="title">Monto promedio (${currency})</h1>
                <p class="indicator"></p>
            `;
        }

        if(this.biddingCount){
            let metroCount = 0;
            let mpCount = 0
            let seiaCount = 0;

            const percentage = (val) => (
                this._decimalPipe.transform(this.biddingCount.total > 0 ? val * 100 / this.biddingCount.total:0,"1.0-0")
            );

            if(this.biddingCount.total > 0){
                metroCount = this.biddingCount.subway;
                mpCount = this.biddingCount.publicMarket;
                seiaCount = this.biddingCount.seia;
            }

            topHtml=`
                <p class="title">Licitaciones con mayor monto (${currency})</p>
                <p class="indicator">${ formatNumber(this.biddingCount.totalAverage)} de un total de ${this.biddingCount.total} Licitaciones</p>
                <p><b>Metro:</b> ${metroCount} (${percentage(metroCount)}%)</p>
                <p><b>Mercado público:</b> ${mpCount} (${percentage(mpCount)}%)</p>
                <p><b>Seia:</b> ${mpCount} (${percentage(seiaCount)}%)</p>
            `;
        }else{
            topHtml=`
                <p class="title">Licitaciones con mayor monto (${currency})</p>
                <p>Sin información</p>
            `;
        }


        const html = `
            <div class="section">
                ${filterHtml}
            </div>
            <div class="section">
                ${avgHtml}
            </div>
            <div class="section">
                ${topHtml}
            </div>
        `;

        x = 16;
        y = 100;
        pdf.html(html,{
            x,
            y:y-16,
            width: 595,
            windowWidth:595,
            html2canvas:{
                ...canvasOptions,
                windowWidth: 1200
            },
            autoPaging: false,
            margin:0
        }).then(()=>{
            


            const pathSmart = `${environment.baseUrl}/assets/images/logos/logo_smartlicitaciones.png`;
            addImageContained(pathSmart,'PNG',16,16,142,64);

            const userData = JSON.parse(sessionStorage.getItem("user"));
            const logoPath = `${environment.imageApi}/${userData?.id}/icon`;
            
            addImageContained(logoPath,'PNG',460,16,120,60,{align: 'right'});

            setTitleStyle();
            pdf.text('REPORTE SMARTLICITACIONES',180, 78, {
                align:'left'
            });
            

            setTitle2Style();
            return html2canvas(this.regionChart.nativeElement,canvasOptions)
        }).then( canvas => {  
            
            x=0;
            y=640;
            const contentDataURL = canvas.toDataURL('image/png');
            pdf.text(`Top 10 monto licitaciones (${this.selectedRegion}) [${currency}]`,
                ml+x,y,{maxWidth:540});
            addImageContained(contentDataURL, 'PNG', ml+x, y+16, 540, 160,{align:'center'});
            return html2canvas(this.top10Chart.nativeElement,canvasOptions);
        }).then(canvas => {
            
            x=0;
            y=435;
            const contentDataURL = canvas.toDataURL('image/png');
            pdf.text(`Monto por región [${currency}]`,
                ml+x,y,{maxWidth:540});
            addImageContained(contentDataURL, 'PNG', ml+x,y+16, 540, 160,{align:'center'});
            return html2canvas(this.categoryChart.nativeElement,{
                ...canvasOptions,
                ignoreElements: (elem:Element)=>{
                    
                    return elem.classList.contains('chart-legend');
                }
            });
        }).then(canvas => {
            x=0;
            y=300;
            const contentDataURL = canvas.toDataURL('image/png');
            pdf.text(`Proporción licitación por categoría [${currency}]`,
                ml+x,y,{maxWidth:540});
            addImageContained(contentDataURL, 'PNG', ml+x, y+16, 540, 120,{align:'center'});

            pdf.save(`${moment().format('YYYYMMDD')}_reporte_licitaciones.pdf`);
        })
        .catch((e)=>{
            console.log(e);
        })
        .finally(()=>{

            this.printing = false;
        });   
    }
}
