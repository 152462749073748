import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigation } from "app/navigation/navigation";
import {
    ColorPalette,
    defaultColorPalette,
    loadColorPalette,
} from "app/utils/colors";
import { getAppNavigation } from "app/utils/navigationUtils";

@Component({
    selector: "horizontal-layout-1",
    templateUrl: "./layout-1.component.html",
    styleUrls: ["./layout-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HorizontalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    colorPalette: ColorPalette;
    sidebarService: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private elementRef: ElementRef;

    /**
     * Constructor
     *
    * @param {_fuseSidebarService} _fuseSidebarService
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Set the defaults
        this.navigation = getAppNavigation();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.colorPalette = loadColorPalette();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
        this.sidebarService = this._fuseSidebarService;
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
