import { NgModule } from "@angular/core";

import { PAGES_ROUTES } from "./pages.routes.module";
import { AuthGuardService } from "app/guard/auth-guard.service";
import { PersistenceService } from "angular-persistence";

import { WalletDashboardModule } from "./dashboards/wallet-dashboard/wallet-dashboard.module";
import { BiddingDashboardModule } from "./dashboards/bidding-dashboard/bidding-dashboard.module";
import { MarketDashboardModule } from "./dashboards/market-dashboard/market-dashboard.module";
import { NotificationModule } from "./notification/notification.module";
import { WalletBiddingModule } from "./biddings/wallet-biddings/wallet-bidding.module";
import { MarketBiddingModule } from "./biddings/market-biddings/market-bidding.module";
import { BiddingBiddingModule } from "./biddings/bidding-biddings/bidding-bidding.module";
import { GeneralDashboardModule } from "./dashboards/general-dashboard/general-dashboard.module";
import { GeneralBiddingModule } from "./biddings/general-biddings/general-biddings.module";

@NgModule({
    imports: [
        PAGES_ROUTES,
        GeneralDashboardModule,
        WalletDashboardModule,
        MarketDashboardModule,
        BiddingDashboardModule,
        GeneralBiddingModule,
        WalletBiddingModule,
        MarketBiddingModule,
        BiddingBiddingModule,
        NotificationModule,
    ],
    providers: [AuthGuardService, PersistenceService],
})
export class PagesModule {}
