<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
                fxHide.gt-md
            ></div>

            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="horizontalNavbar"
            >
                <div class="logo ml-16">
                    <!-- <img class="logo-icon w-140" src="assets/images/logos/ferrovial.svg"> -->
                    <img class="logo-icon w-140" [src]="logoPath" />
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
        </div>

        <div
            class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <div class="toolbar-separator"></div>

            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
                    <span
                        class="username mr-12"
                        fxHide
                        fxShow.gt-sm
                        *ngIf="
                            currentUser !== undefined && currentUser !== null
                        "
                    >
                        {{ currentUser.firstname }} {{ currentUser.lastname }}
                    </span>
                    <mat-icon class="s-16" fxHide.xs
                        >keyboard_arrow_down</mat-icon
                    >
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <!-- <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <!-- <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu> -->

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="openNotificationPanel()"
                aria-label="Abrir panel de notificaciones"
            >
                <mat-icon class="secondary-text"
                    >notification_important</mat-icon
                >
                <div class="notification-unread-count">
                    {{ notifications.length }}
                </div>
            </button>

            <div class="notification-panel" *ngIf="notificationPanel">
                <h1 class="header">Panel de notificaciones</h1>
                <div *ngIf="notifications.length > 0">
                    <div
                        class="notification-element"
                        *ngFor="let n of notifications"
                        (click)="handleOnClickNotification(n)"
                    >
                        <div fxLayout="column">
                            <div fxFlex="100" id="sender-date">
                                {{ n.sender }} -
                                {{ n.date | date: "dd-MM-yyyy HH:mm" }}:
                            </div>
                            <div fxlayout="row" fxlayoutalign="start center">
                                <div fxLayout="column" id="msg">
                                    {{ n.msg.trim() }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="notification-unread-0-element"
                    *ngIf="notifications.length === 0"
                >
                    <span id="unread-0-text">
                        No hay notificaciones sin leer.
                    </span>
                </div>
            </div>

            <!-- <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->
        </div>
    </div>
</mat-toolbar>
