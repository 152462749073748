export const ORIGIN_PUBLIC_MARKET = 'PUBLIC_MARKET';
export const ORIGIN_SEIA = 'SEA_GOB';
export const ORIGIN_SUBWAY = 'SUBWAY';

export const ORIGINS: Array<any> = [
    {label:'Mercado público',value:ORIGIN_PUBLIC_MARKET},
    {label:'Metro',value:ORIGIN_SUBWAY},
    {label:'Seia',value:ORIGIN_SEIA}
];

export const ORIGIN_STATES: any = {
    "PUBLIC_MARKET":[
        {label:'Publicada',value:'Publicada'},
        {label:'Cerrada',value:'Cerrada'},
        {label:'Desierta (o art. 3 ó 9 Ley 19.886)',value:'Desierta (o art. 3 ó 9 Ley 19.886)'},
        {label:'Adjudicada',value:'Adjudicada'},
        {label:'Readjudicada',value:'Readjudicada'},
        {label:'Adjudicada con Orden de Compra Rechazadas',value:'Adjudicada con Orden de Compra Rechazadas'},
        {label:'Autorizada para Adjudicación',value:'Autorizada para Adjudicación'},
        {label:'Enviada a Autorizar para Adjudicación',value:'Enviada a Autorizar para Adjudicación'},
        {label:'Autorizada para Deserción',value:'Autorizada para Deserción'},
        {label:'Enviada a Autorizar para Deserción',value:'Enviada a Autorizar para Deserción'},
        {label:'Revocada',value:'Revocada'},
        {label:'Suspendida',value:'Suspendida'},
    ],
    "SUBWAY":[
        {label:'Publicada',value:'Publicada'},
        {label:'Consultas y Respuestas',value:'Consultas y Respuestas'},
        {label:'En Evaluación',value:'En Evaluación'},
        {label:'Desierto',value:'Desierto'},
        {label:'Adjudicado',value:'Adjudicado'}

    ],
    "SEA_GOB":[
        {label:'En Admisión',value:'En Admisión'},
        {label:'En Calificación',value:'En Calificación'},
        {label:'Aprobado',value:'Aprobado'},
        {label:'Rechazado',value:'Rechazado'},
        {label:'No Admitido a Tramitación',value:'No Admitido a Tramitación'},
        {label:'No calificado',value:'No calificado'},
        {label:'Desistido',value:'Desistido'},
        {label:'Abandonado',value:'Abandonado'},
        {label:'Caducado',value:'Caducado'},
        {label:'Revocado',value:'Revocado'},
        {label:'Renuncia RCA',value:'Renuncia RCA'},
        {label:'Ingresados Art.94 RSEIA',value:'Ingresados Art.94 RSEIA'}
    ]
};

