import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { PersistenceService } from "angular-persistence";
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        public router: Router,
        private persistenceService: PersistenceService
    ) {}

    isLoggedIn(): boolean {
        //return true;
        if (sessionStorage.getItem("token")) {
            return true;
        } else {
            return false;
        }
    }

    canActivate(): boolean {
        if (!this.isLoggedIn()) {
            this.router.navigate(["login"]);
            return false;
        }
        return true;
    }
}
