<div id="notifications" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div
            class="header accent"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center"
        >
            <!-- APP TITLE -->
            <div
                class="logo mb-8 mb-md-0"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-icon
                    class="logo-icon s-32 mr-16"
                    [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                >
                    assignment
                </mat-icon>
                <span
                    class="logo-text h1 accent-text"
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', x: '-25px' }
                    }"
                >
                    Notificaciones
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper fuse-white mx-32 mx-md-0">
                <div
                    class="search"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Buscar notificaciones" />
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- CONF BUTTON -->
            <button
                mat-raised-button
                (click)="configNotifications()"
                class="conf-notification-btn mt-8 mt-md-0"
            >
                <span>Configurar notificaciones</span>
            </button>
            <!-- / CONF BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card mb-16">
            <mat-progress-bar
                *ngIf="
                    dataSource == undefined || dataSource == null || isLoading
                "
                [color]="'warn'"
                mode="indeterminate"
            >
            </mat-progress-bar>

            <mat-table
                class="notifications-history-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Fecha de publicación</mat-header-cell
                    >
                    <mat-cell *matCellDef="let notification">
                        <p class="text-truncate">
                            {{ notification.date | date: "dd-MM-yyyy" }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Total de licitaciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let notification">
                        <p class="text-truncate">{{ notification.total }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="message">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Mensaje</mat-header-cell
                    >
                    <mat-cell *matCellDef="let notification">
                        <button
                            mat-raised-button
                            (click)="openMessage(notification.message)"
                            class="mt-8 mt-md-0"
                            style="background-color: #fdd835"
                        >
                            <span>Ver mensaje</span>
                        </button>
                        <button
                            mat-raised-button
                            (click)="deleteMessage(notification)"
                            class="mt-8 ml-8 mt-md-0"
                            style="background-color: #c62828; color:white;"
                        >
                            <span>Eliminar</span>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let notification; columns: displayedColumns"
                    matRipple
                >
                </mat-row>
            </mat-table>

            <mat-paginator
                #paginator
                [length]="dataSourceLength"
                [pageIndex]="0"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
