import { Component, ViewChild, ElementRef } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    
} from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { BarVerticalComponent, PieChartComponent } from "@swimlane/ngx-charts";
import { RANGES } from "app/shared/js/pickerRanges";
import { REGIONS } from "app/shared/js/regions";
import { FORM_END_VALUE, FORM_START_VALUE } from "app/shared/js/startValues";
import { Region } from "app/shared/models/region.model";
import { BiddingOriginPipe, transformOrigin } from "app/shared/pipes/bidding-origin.pipe";
import { BiddingRegionPipe, transformRegion } from "app/shared/pipes/bidding-region.pipe";
import { FormatNumberPipe, transformNumberFormat } from "app/shared/pipes/format-number.pipe";
import { OriginVariablesPipe } from "app/shared/pipes/origin-variables.pipe";
import { BiddingDashboardService } from "app/shared/services/bidding-dashboars.service";
import { ColorPalette, loadColorPalette } from "app/utils/colors";
import * as shape from "d3-shape";
import * as _ from "lodash";
import * as moment from "moment";
import SvgSaver from "svgsaver";
import { MillionPipe } from "app/shared/pipes/million.pipe";

@Component({
    selector: "bidding-dashboard",
    templateUrl: "./bidding-dashboard.component.html",
    styleUrls: ["./bidding-dashboard.component.scss"],
})
export class BiddingDashboardComponent {
    amountPerRegionGraph: any = {};
    amountPerCategoryGraph: any = {};
    top10Graph: any = {};

    amountPerRegionGraphData: Array<any> = [];
    amountPerCategoryData: Array<any> = [];
    top10GraphData: Array<any> = [];

    dashboardFilter: any = {};

    form: FormGroup;
    regions: Array<Region> = REGIONS;
    pickerRanges: object = RANGES;

    maxBidding: any = null;
    biddingCount: any = null;
    biddingsPerState: any = null;
    biddingsPerStateKeys: any = null;
    biddingsPerRegion: any = null;
    biddingsPerRegionKeys: any = null;
    biddingsPerRegionRegions: any = null;

    providers: Array<string> = [];

    selectedRegion: string = "Todas las regiones";
    selectedCurrency: number = 0;

    loading = {
        amountPerRegionGraph: true,
        top10Graph: true,
        maxAmount: true,
        biddingCount: true,
        amountPerCategory: true,
    };

    // Referencias usadas para exportar pdf
    @ViewChild('regionChart',{static:false}) regionChart: ElementRef;
    @ViewChild('top10Chart',{static:false}) top10Chart: ElementRef;
    @ViewChild('categoryChart',{static:false}) categoryChart: ElementRef;

    

    yAxisTickFormatting = (value) =>`${this._formatNumberPipe.transform(this._millionPipe.transform(value),value)}`;
    yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

    routeSub: any;
    colorPalette: ColorPalette;
    defaultFormFields: any;

    svgSaver = new SvgSaver();

    @ViewChild("amountPerCategory", { static: true })
    amountPerCategory: PieChartComponent;

    @ViewChild("amountPerRegion", { static: true })
    amountPerRegion: BarVerticalComponent;

    @ViewChild("top10", { static: true })
    top10: BarVerticalComponent;

    temporalFilter: any = null;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _formBuilder: FormBuilder,
        private _biddingDashboardService: BiddingDashboardService,
        private _biddingRegionPipe: BiddingRegionPipe,
        private _millionPipe: MillionPipe,
        private _router: Router,
        private _formatNumberPipe: FormatNumberPipe
    ) {
        this.colorPalette = loadColorPalette();
    }

    ngOnInit(): void {
        this.routeSub = this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.urlAfterRedirects !== "/app/bidding-dashboard") {
                    sessionStorage.setItem(
                        "bidding-dashboard-filter",
                        JSON.stringify(this.dashboardFilter)
                    );
                    sessionStorage.setItem(
                        "bidding-dashboard-form-fields",
                        JSON.stringify(this.defaultFormFields)
                    );
                }
            }
        });

        this.form = this._formBuilder.group({
            referenceDate: new FormControl(moment(), []),
            closingDate: new FormControl(moment().add(1, "year"), []),
            sign: new FormControl(0, []),
            currency: new FormControl(0, []),
            startAmount: new FormControl(0),
            endAmount: new FormControl(FORM_END_VALUE),
            region: new FormControl(this.regions[0].value, []),
        });

        //bar
        this.amountPerRegionGraph = {
            currentRange: "",
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: "Days",
            showYAxisLabel: false,
            yAxisLabel: "Isues",
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
                //domain: ['#ffc700','#FFAA5A', '#FFD25A', '#FFF05A','#FF785A']
            },
            onSelect: (ev) => {
                this.startLoading();
                const filter = _.cloneDeep(this.dashboardFilter);
                const keyArray = filter.where.and.map((o) => Object.keys(o)[0]);
                if (!keyArray.includes("region")) {
                    const reg = this._biddingRegionPipe.reverseTransform(
                        ev.name
                    );
                    filter.where.and.push({ region: reg });
                    this.form.controls["region"].setValue(reg);
                }
                this.selectedRegion = ev.name;
                if (this.temporalFilter !== null) this.handleTempFilter(filter);
                else {
                    this.temporalFilter = filter;
                    this.getData(filter);
                }
            },
            supporting: {
                currentRange: "",
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: "Days",
                showYAxisLabel: false,
                yAxisLabel: "Isues",
                scheme: {
                    domain: this.colorPalette.charts,
                    //domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
                },
                curve: shape.curveBasis,
            },
        };

        this.top10Graph = {
            currentRange: "",
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: "Days",
            showYAxisLabel: false,
            yAxisLabel: "Isues",
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
            },
            onSelect: (ev) => {
                console.log(ev);
            },
            supporting: {
                currentRange: "",
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: "Days",
                showYAxisLabel: false,
                yAxisLabel: "Isues",
                scheme: {
                    domain: this.colorPalette.charts,
                    //domain: ['#ffc700','#FFAA5A', '#FFD25A', '#FFF05A','#FF785A']
                },
                curve: shape.curveBasis,
            },
        };

        //pie
        this.amountPerCategoryGraph = {
            legend: true,
            explodeSlices: false,
            labels: true,
            doughnut: false,
            gradient: false,
            selectedKey: null,
            scheme: {
                domain: this.colorPalette.charts,
                //domain: ['#ffc700','#FFAA5A', '#FFD25A', '#FFF05A','#FF785A']
            },
            onSelect: (ev) => {
                this.startLoading();
                const filter = _.cloneDeep(this.dashboardFilter);
                if (typeof ev === "string") {
                    filter.where.and.push({ categoryName: ev });
                } else {
                    filter.where.and.push({ categoryName: ev.name });
                }

                if (this.temporalFilter !== null){
                    this.handleTempFilter(filter);
                }
                else {
                    this.temporalFilter = filter;
                    this.getData(filter);
                }
            },
        };

        if (
            sessionStorage.getItem("bidding-dashboard-filter") !== null &&
            JSON.parse(sessionStorage.getItem("bidding-dashboard-filter")) !==
                null
        ) {
            this.dashboardFilter = JSON.parse(
                sessionStorage.getItem("bidding-dashboard-filter")
            );
            this.getData(this.dashboardFilter);
            if (
                sessionStorage.getItem("bidding-dashboard-form-fields") !==
                "undefined"
            ) {
                this.defaultFormFields = JSON.parse(
                    sessionStorage.getItem("bidding-dashboard-form-fields")
                );
                this.setFilterDefaultValues(this.defaultFormFields);
            }
        } else {
            this.submit();
        }
    }

    ngAfterContentInit() {
        this.formatNumber("startAmount");
        this.formatNumber("endAmount");
    }

    startLoading() {
        this.loading.amountPerRegionGraph = true;
        this.loading.top10Graph = true;
        this.loading.maxAmount = true;
        this.loading.biddingCount = true;
        this.loading.amountPerCategory = true;
    }

    handleTempFilter(newFilter: any) {
        const newFilterKeyArray = newFilter.where.and.map(
            (o) => Object.keys(o)[0]
        );
        const oldFilterKeyArray = this.temporalFilter.where.and.map(
            (o) => Object.keys(o)[0]
        );
        const diffEle = newFilterKeyArray.filter(
            (x) => !oldFilterKeyArray.includes(x)
        );

        if (diffEle.length > 0) {
            const index = newFilterKeyArray.indexOf(diffEle[0]);
            this.temporalFilter.where.and.push(newFilter.where.and[index]);
            this.getData(this.temporalFilter);
        } else {
            this.getData(this.temporalFilter);
        }
    }

    setFilterDefaultValues(formFields: any) {
        formFields.referenceDate = moment(formFields.referenceDate).utc();
        formFields.closingDate = moment(formFields.closingDate).utc();
        this.form.patchValue(formFields);
    }

    getData(filter: any): void {
        this.selectedRegion = this._biddingRegionPipe.transform(
            this.form.value.region
        );

        this._biddingDashboardService
            .getBiddingWithMaxAmount(filter)
            .subscribe((response) => {
                //console.log(response);
                this.loading.maxAmount = false;
                this.maxBidding = response;
            });

        this._biddingDashboardService
            .getBiddingCount(filter, this.selectedCurrency)
            .subscribe((response) => {
                //console.log(response);
                this.loading.biddingCount = false;
                this.biddingCount = response;
            });

        this._biddingDashboardService
            .getBiddingAmountPerCategory(filter, this.selectedCurrency)
            .subscribe((response: any) => {
                //console.log(response);
                this.loading.amountPerCategory = false;
                this.amountPerCategoryData = response;
            });

        this._biddingDashboardService
            .getBiddingAmountPerRegion(filter, this.selectedCurrency)
            .subscribe((response) => {
                //console.log(response);
                this.biddingsPerRegion = response;
                this.biddingsPerRegionRegions = Object.keys(
                    this.biddingsPerRegion
                );
                if(this.biddingsPerRegionRegions.length > 0){
                    this.biddingsPerRegionKeys = Object.keys(
                        this.biddingsPerRegion[this.biddingsPerRegionRegions[0]]
                    );
                    this.amountPerRegionGraph.selectedKey = Object.keys(
                        this.biddingsPerRegion[this.biddingsPerRegionRegions[0]]
                    )[0];
                    this.amountPerRegionGraphData =
                        this.biddingsPerRegionRegions.map((region: any) => {
                            return {
                                name: region,
                                value: this.biddingsPerRegion[region][
                                    this.amountPerRegionGraph.selectedKey
                                ],
                            };
                        });
                }else{
                    this.amountPerRegionGraphData = [];
                }
                this.loading.amountPerRegionGraph = false;
            });

        this.getTop10(filter);
    }

    onBarKeyChange(value: any) {
        this.amountPerRegionGraphData = this.biddingsPerRegionRegions.map(
            (region: any) => {
                return {
                    name: region,
                    value: this.biddingsPerRegion[region][value],
                };
            }
        );
    }

    getTop10(filter: any) {
        this._biddingDashboardService
            .getTop10(filter, this.selectedCurrency)
            .subscribe((response: any) => {
                this.loading.top10Graph = false;
                this.top10GraphData = response;
            });
    }

    /**
     * Submit form action
     *
     * @param null
     */
    submit(): void {
        this.loading.amountPerRegionGraph = true;
        this.loading.top10Graph = true;
        this.loading.maxAmount = true;
        this.loading.biddingCount = true;

        this.selectedCurrency = this.form.value.currency;

        this.dashboardFilter.where = { and: [] };
        this.dashboardFilter.order = "publicationDate DESC";

        if (
            this.form.value.sign !== null &&
            this.form.value.startAmount !== null &&
            this.form.value.startAmount !== "" &&
            this.form.value.endAmount !== null &&
            this.form.value.endAmount !== ""
        ) {
            const startAmount =
                typeof this.form.value.startAmount == "string"
                    ? parseInt(this.form.value.startAmount.replace(/\./g, ""))
                    : this.form.value.startAmount;
            const endAmount =
                typeof this.form.value.endAmount == "string"
                    ? parseInt(this.form.value.endAmount.replace(/\./g, ""))
                    : this.form.value.endAmount;

            if (this.selectedCurrency === 0) {
                this.dashboardFilter.where.and.push({
                    or: [
                        {
                            and: [
                                { estimatedAmount: { gte: startAmount } },
                                { estimatedAmount: { lte: endAmount } },
                            ],
                        },
                        //{ estimatedAmount: null },
                    ],
                });
            } else if (this.selectedCurrency === 1) {
                this.dashboardFilter.where.and.push({
                    or: [
                        {
                            and: [
                                { estimatedAmountDolar: { gte: startAmount } },
                                { estimatedAmountDolar: { lte: endAmount } },
                            ],
                        },
                        //s{ estimatedAmountDolar: null },
                    ],
                });
            } else if (this.selectedCurrency === 2) {
                this.dashboardFilter.where.and.push({
                    or: [
                        {
                            and: [
                                { estimatedAmountUf: { gte: startAmount } },
                                { estimatedAmountUf: { lte: endAmount } },
                            ],
                        },
                        { estimatedAmountUf: null },
                    ],
                });
            }
        }

        if (this.form.value.region !== null && this.form.value.region !== 0) {
            this.dashboardFilter.where.and.push({
                region: this.form.value.region,
            });
        }

        if (
            this.form.value.closingDate !== null &&
            this.form.value.closingDate !== null &&
            this.form.value.referenceDate !== null &&
            this.form.value.referenceDate !== null
        ) {
            if (typeof this.form.value.closingDate === "string") {
                this.dashboardFilter.where.and.push({
                    closingDate: { lte: this.form.value.closingDate },
                });
                this.dashboardFilter.where.and.push({
                    closingDate: { gte: this.form.value.referenceDate },
                });
            } else {
                this.dashboardFilter.where.and.push({
                    closingDate: {
                        lte: this.form.value.closingDate
                            .utc()
                            .endOf("day")
                            .format(),
                    },
                });
                this.dashboardFilter.where.and.push({
                    closingDate: {
                        gte: this.form.value.referenceDate
                            .utc()
                            .startOf("day")
                            .format(),
                    },
                });
            }
        }

        [
            "Adjudicado","Adjudicada","Cerrada","Desierta","Desierta (o art. 3 ó 9 Ley 19.886)",
            "Desierto"
        ].forEach((v)=>{
            this.dashboardFilter.where.and.push({ status: { neq: v } })
        });
        

        //this.dashboardFilter.where.and.push({ status: { neq: "Adjudicado" } });
        //this.dashboardFilter.where.and.push({ status: { neq: "Adjudicada" } });
        //this.dashboardFilter.where.and.push({ status: { nlike: "Desierta" } });
        this.dashboardFilter.where.and.push({ status: {nin:[
            "Adjudicado","Adjudicada","Cerrada","Desierta","Desierta (o art. 3 ó 9 Ley 19.886)",
            "Desierto"
        ]} });

        this.dashboardFilter.where.and.push({
            or: [{ dashboard: 1 }, { dashboard: 0 }],
        });

        this.setDefaultFormFields();
        this.getData(this.dashboardFilter);
    }

    setDefaultFormFields() {
        this.defaultFormFields = {
            closingDate: this.form.value.closingDate,
            referenceDate: this.form.value.referenceDate,
            sign: this.form.value.sign,
            currency: this.form.value.currency,
            startAmount: this.form.value.startAmount,
            endAmount: this.form.value.endAmount,
            region: this.form.value.region,
        };
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    formatNumber(val: string) {
        let formVal: number = 0;
        if (typeof this.form.controls[val].value === "string") {
            formVal = parseInt(
                this.form.controls[val].value.replace(/\./g, "")
            );
        } else if (typeof this.form.controls[val].value === "number") {
            formVal = this.form.controls[val].value;
        }
        const tVal = this._formatNumberPipe.transform(
            isNaN(formVal) ? 0 : formVal
        );
        this.form.controls[val].setValue(tVal);
    }

    clearFilter() {
        this.temporalFilter = null;

        this.form.setValue({
            referenceDate: moment(),
            closingDate: moment().add(1, "year"),
            sign: 0,
            currency: 0,
            startAmount: 0,
            endAmount: FORM_END_VALUE,
            region: this.regions[0].value,
        });

        this.formatNumber("startAmount");
        this.formatNumber("endAmount");

        this.submit();
    }


    
}
