import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fuseAnimations } from "@fuse/animations";
import { fromEvent, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BiddingService } from "app/shared/services/bidding.service";
import { BiddingOriginPipe } from "app/shared/pipes/bidding-origin.pipe";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Region } from "app/shared/models/region.model";
import { REGIONS } from "app/shared/js/regions";
import { RANGES } from "app/shared/js/pickerRanges";
import { MatTableDataSource } from "@angular/material/table";
import { NavigationEnd, Router } from "@angular/router";
import {
    ALL_TABLE_COLUMNS,
    DISPLAYED_TABLE_COLUMNS,
} from "app/shared/js/tableColumns";
import * as XLSX from "xlsx";
import { BiddingColumnsPipe } from "app/shared/pipes/bidding-columns.pipe";
import { FormatNumberPipe } from "app/shared/pipes/format-number.pipe";
import { FORM_END_VALUE } from "app/shared/js/startValues";
import { loadColorPalette } from "app/utils/colors";
import { ORIGINS, ORIGIN_STATES } from "app/utils/origins";

@Component({
    selector: "general-biddings",
    templateUrl: "./general-biddings.component.html",
    styleUrls: ["./general-biddings.component.scss"],
    animations: fuseAnimations,
})
export class GeneralBiddingComponent {
    dataSource: MatTableDataSource<any>;
    dataSourceLength: number;
    pageSize: number = 10;
    pageIndex: number = 0;

    isLoading: boolean = false;

    displayedColumns = DISPLAYED_TABLE_COLUMNS;
    allColumns = ALL_TABLE_COLUMNS;

    dashboardFilter: any = {};
    routeSub: any;

    form: FormGroup;
    regions: Array<Region> = REGIONS;
    origins: Array<any> = [
        {label:'Todos',value:''},
        ...ORIGINS
    ];
    originStates: Array<any> = [];
    pickerRanges: object = RANGES;

    selectedCurrency: number = 0;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    filteredProviders: Observable<string[]>;
    myControl = new FormControl();
    options: string[] = ["One", "Two", "Three"];

    private _unsubscribeAll: Subject<any>;

    defaultFormFields: any;

    constructor(
        private _biddingService: BiddingService,
        private _biddingOriginPipe: BiddingOriginPipe,
        private _fuseSidebarService: FuseSidebarService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _biddingColumnsPipe: BiddingColumnsPipe,
        private _formatNumberPipe: FormatNumberPipe
    ) {
        this._unsubscribeAll = new Subject();
        loadColorPalette();
    }

    ngOnInit(): void {
        this.routeSub = this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.urlAfterRedirects !== "/app/general-biddings") {
                    sessionStorage.setItem(
                        "general-dashboard-filter",
                        JSON.stringify(this.dashboardFilter)
                    );
                    sessionStorage.setItem(
                        "general-dashboard-form-fields",
                        JSON.stringify(this.defaultFormFields)
                    );
                }
            }
        });

        this.form = this._formBuilder.group({
            startDate: new FormControl(moment().subtract(1, "year"), []),
            endDate: new FormControl(moment(), []),
            sign: new FormControl(0, []),
            currency: new FormControl(0, []),
            startAmount: new FormControl(0),
            endAmount: new FormControl(FORM_END_VALUE),
            region: new FormControl(this.regions[0].value, []),
            origin: new FormControl(''),
            status: new FormControl([])
        });

        // Revisa si cambia el origen para mostrar valores acordes
        this.form.get('origin').valueChanges.subscribe(val => {
            this.form.get('status').setValue([]);
            this.originStates = ORIGIN_STATES[val] != null ? ORIGIN_STATES[val]: [];
        });

        if (
            sessionStorage.getItem("general-dashboard-filter") !== null &&
            JSON.parse(sessionStorage.getItem("general-dashboard-filter")) !==
                null
        ) {
            this.dashboardFilter = JSON.parse(
                sessionStorage.getItem("general-dashboard-filter")
            );
            this.getData();
            if (
                sessionStorage.getItem("general-dashboard-form-fields") !==
                "undefined"
            ) {
                this.defaultFormFields = JSON.parse(
                    sessionStorage.getItem("general-dashboard-form-fields")
                );
                this.setFilterDefaultValues(this.defaultFormFields);
            }
        } else {
            this.submit();
        }

        fromEvent(this.filter.nativeElement, "keyup")
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }

                this.dataSource.filter =
                    this._biddingOriginPipe.reverseTransform(
                        this.filter.nativeElement.value
                    );
            });
    }

    ngAfterContentInit() {
        this.formatNumber("startAmount");
        this.formatNumber("endAmount");
    }

    setFilterDefaultValues(formFields: any) {
        formFields.endDate = moment(formFields.endDate).utc();
        formFields.startDate = moment(formFields.startDate).utc();
        this.form.patchValue(formFields);
    }

    getData() {
        this.isLoading = true;
        this._biddingService
            .getAllGeneralBiddings({
                ...this.dashboardFilter,
                limit: this.pageSize,
                skip: this.pageSize*this.pageIndex
            })
            .subscribe((response: any) => {
                this.dataSource = new MatTableDataSource(response.items);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                setTimeout(()=>{
                    this.paginator.pageIndex = this.pageIndex;
                    this.paginator.pageSize = this.pageSize;
                    this.paginator.length = response.count;
                });
            },
            (error)=>{console.log(error);},
            ()=>{this.isLoading = false;});
    }

    selectColumns(columns: Array<string>): void {
        this.displayedColumns = columns;
    }

    /**
     * Submit form action
     *
     * @param null
     */
    submit(): void {
        this.selectedCurrency = this.form.value.currency;

        this.dashboardFilter.where = { and: [] };
        this.dashboardFilter.order = "publicationDate DESC";

        if (
            this.form.value.startDate !== null &&
            this.form.value.endDate !== null
        ) {
            if (typeof this.form.value.endDate === "string") {
                this.dashboardFilter.where.and.push({
                    publicationDate: { lte: this.form.value.endDate },
                });
                this.dashboardFilter.where.and.push({
                    publicationDate: { gte: this.form.value.startDate },
                });
            } else {
                this.dashboardFilter.where.and.push({
                    publicationDate: {
                        lte: this.form.value.endDate
                            .utc()
                            .endOf("day")
                            .format(),
                    },
                });
                this.dashboardFilter.where.and.push({
                    publicationDate: {
                        gte: this.form.value.startDate
                            .utc()
                            .startOf("day")
                            .format(),
                    },
                });
            }
        }

        if (
            this.form.value.sign !== null &&
            this.form.value.startAmount !== null &&
            this.form.value.startAmount !== "" &&
            this.form.value.endAmount !== null &&
            this.form.value.endAmount !== ""
        ) {
            const startAmount =
                typeof this.form.value.startAmount == "string"
                    ? parseInt(this.form.value.startAmount.replace(/\./g, ""))
                    : this.form.value.startAmount;
            const endAmount =
                typeof this.form.value.endAmount == "string"
                    ? parseInt(this.form.value.endAmount.replace(/\./g, ""))
                    : this.form.value.endAmount;

            if (this.selectedCurrency === 0) {
                this.dashboardFilter.where.and.push({
                    estimatedAmount: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmount: { lte: endAmount },
                });
            } else if (this.selectedCurrency === 1) {
                this.dashboardFilter.where.and.push({
                    estimatedAmountDolar: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmountDolar: { lte: endAmount },
                });
            } else if (this.selectedCurrency === 2) {
                this.dashboardFilter.where.and.push({
                    estimatedAmountUf: { gte: startAmount },
                });
                this.dashboardFilter.where.and.push({
                    estimatedAmountUf: { lte: endAmount },
                });
            }
        }

        if (this.form.value.region !== null && this.form.value.region !== 0) {
            this.dashboardFilter.where.and.push({
                region: this.form.value.region,
            });
        }

        if(this.form.value.origin !== null && this.form.value.origin !== ''){
            this.dashboardFilter.where.and.push({
                origin: this.form.value.origin
            });
        }

        if(this.form.value.status !== null && this.form.value.status.length > 0){
            this.dashboardFilter.where.and.push({
                status: {
                    inq: this.form.value.status
                }
            });
        }

        this.setDefaultFormFields();
        this.pageIndex = 0;
        this.getData();
    }

    setDefaultFormFields() {
        this.defaultFormFields = {
            startDate: this.form.value.startDate,
            endDate: this.form.value.endDate,
            sign: this.form.value.sign,
            currency: this.form.value.currency,
            startAmount: this.form.value.startAmount,
            endAmount: this.form.value.endAmount,
            region: this.form.value.region,
            origin: this.form.value.origin,
            status: this.form.value.status
        };
    }

    exportData() {
        
        this.isLoading = true;
        this._biddingService
            .getAllBiddings({
                ...this.dashboardFilter,
            })
            .subscribe((response: any) => {
                const data = this.getExportValues(response.items);
    
                const workBook = XLSX.utils.book_new();
                const workSheet = XLSX.utils.json_to_sheet(data);
                const wscols = ALL_TABLE_COLUMNS.map((v) => {
                    return { wch: 30 };
                });
                workSheet["!cols"] = wscols;
                XLSX.utils.book_append_sheet(workBook, workSheet, "data");
    
                return XLSX.writeFile(workBook, "licitaciones.xlsx");
                
            },
            (error)=>{
                console.log(error);
            },
            ()=>{
                this.isLoading = false;
                console.log('Always')
            });
    }

    getExportValues(biddings: any) {
        return biddings.map((b: any) => {
            let obj: any = {};

            for (let index = 0; index < ALL_TABLE_COLUMNS.length; index++) {
                const col = ALL_TABLE_COLUMNS[index];
                if (col == "origin")
                    obj[this._biddingColumnsPipe.transform(col)] =
                        this._biddingOriginPipe.transform(b[col]);
                else obj[this._biddingColumnsPipe.transform(col)] = b[col];
            }

            return obj;
        });
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    formatNumber(val: string) {
        let formVal: number = 0;
        if (typeof this.form.controls[val].value === "string") {
            formVal = parseInt(
                this.form.controls[val].value.replace(/\./g, "")
            );
        } else if (typeof this.form.controls[val].value === "number") {
            formVal = this.form.controls[val].value;
        }
        const tVal = this._formatNumberPipe.transform(
            isNaN(formVal) ? 0 : formVal
        );
        this.form.controls[val].setValue(tVal);
    }

    clearFilter() {
        this.form.setValue({
            startDate: moment().subtract(1, "year"),
            endDate: moment(),
            sign: 0,
            currency: 0,
            startAmount: 0,
            endAmount: FORM_END_VALUE,
            region: this.regions[0].value,
            origin: '',
            status: []
        });

        this.formatNumber("startAmount");
        this.formatNumber("endAmount");

        this.submit();
    }

    onPageChange(event:PageEvent){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.getData();
    }
}
