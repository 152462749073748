
<div 
*ngIf="printing"
class="loader">
    <div class="loader-center">
        <mat-progress-spinner style="margin:auto;" mode="indeterminate"></mat-progress-spinner>
        <p class="loader-text">Generando informe...</p>
    </div>
</div>

<button
    mat-fab
    style="position: fixed; bottom: 16px; right: 16px;"
    (click)="savePdf()"
    aira-label="Descargar"
>
    <mat-icon>print</mat-icon>
</button>
