import { NgModule } from '@angular/core';

import { ConfigNotificationsComponent, NewNotificationComponent, NotificationComponent, OpenNotificationComponent } from './notification.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        NotificationComponent,
        NewNotificationComponent,
        ConfigNotificationsComponent,
        OpenNotificationComponent
    ],
    entryComponents: [
        NewNotificationComponent,
        ConfigNotificationsComponent,
        OpenNotificationComponent
    ],
    imports: [
        //Material
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSortModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressBarModule,

        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule

    ],
    exports: [
        NotificationComponent
    ]
})
export class NotificationModule {} 