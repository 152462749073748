import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { ReportBtnComponent } from './report-btn.component';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OriginVariablesPipe } from 'app/shared/pipes/origin-variables.pipe';
import { FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DecimalPipe } from '@angular/common';
import { MillionPipe } from 'app/shared/pipes/million.pipe';
import { CurrencyTypePipe } from 'app/shared/pipes/currency-type.pipe';
import { BiddingRegionPipe } from 'app/shared/pipes/bidding-region.pipe';
import { BiddingOriginPipe } from 'app/shared/pipes/bidding-origin.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MM-YYYY'
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@NgModule({
    declarations: [
        ReportBtnComponent
    ],
    providers: [
        CurrencyTypePipe,
        FormatNumberPipe,
        DecimalPipe,
        MillionPipe,
        BiddingRegionPipe,
        BiddingOriginPipe,
        OriginVariablesPipe,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    imports     : [
    
    RouterModule,
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    exports     : [
        ReportBtnComponent
    ]
})
export class ReportBtnModule
{
}
