
import { Pipe, PipeTransform } from '@angular/core';

export const currencyTypeTransform = (currency: number):any => {
    if (currency === undefined) {
        return currency;
    }

    switch (currency) {
        case 0:
            return 'CLP'
        case 1:
            return 'US Dólar'
        case 2:
            return 'UF'
        default:
            return currency;
    }
};

@Pipe({
    name: 'CurrencyType',
    pure: false
})
export class CurrencyTypePipe implements PipeTransform {

    transform(currency: number): any {
        return currencyTypeTransform(currency)
    }

}