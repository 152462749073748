
import { Pipe, PipeTransform } from '@angular/core';


export const transformNumberFormat = (roundNumber: number, number?: number): any => {
    if (roundNumber === undefined || roundNumber === null) {
        return roundNumber;
    }

    let s = '';

    if(number !== undefined){
        if(number < 1000000 && number >= 1000){
            s = ' Miles';
        }else if(number >= 1000000){
            s = ' Millones';
        }
    }

    return roundNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + s;
    
}

@Pipe({
    name: 'formatNumber',
    pure: false
})
export class FormatNumberPipe implements PipeTransform {

    transform(roundNumber: number, number?: number): any {
        return transformNumberFormat(roundNumber,number);
    }

}
